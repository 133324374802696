import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { dateOptions, defaultDataState } from './default.states'
import { IDataProps, IScheduleByMonthPageContextProps, IScheduleByMonthPageProviderProps, Item } from './interfaces'
import { GET_CLASSES_WEEK } from './schemas.queries'

const ScheduleByMonthPageContext = createContext<IScheduleByMonthPageContextProps>({
  currentDay: new Date(),
  changeCurrentDay: () => Object,
  selectedDay: new Date(),
  changeSelectedDay: () => Object,
  isLoading: false,
  data: defaultDataState,
  isRefetching: false,
  extensiveSelectedDay: '',
  daysArray: [{ formattedDay: '', className: '' }],
  itemsByDay: [{ day: '', items: [] }],
  daysOfWeekEN: [''],
  formattedMonthWithUppercase: '',
})

dayjs().locale('pt-br')

const ScheduleByMonthPageProvider = ({ children }: IScheduleByMonthPageProviderProps) => {
  const [currentDay, setCurrentDay] = useState<Date>(new Date())

  const [selectedDay, setSelectedDay] = useState<Date>(new Date())

  const today = dayjs(currentDay)

  const firstDayOfMonth = today.startOf('month')
  const lastDayOfMonth = today.endOf('month')

  const { isLoading, data, remove, isRefetching, refetch } = useQueryGraphQLClient<IDataProps>(
    'moment',
    'GET_CLASSES_WEEK',
    GET_CLASSES_WEEK,
    { dateStart: firstDayOfMonth, dateEnd: lastDayOfMonth, showScheduledClasses: true },
  )

  const daysOfWeekEN = useMemo(() => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], [])

  const daysArray = useMemo(() => {
    const tempDaysArray: Array<{ formattedDay: string; className: string }> = []
    const startDayOfWeek = firstDayOfMonth.day()

    for (let i = startDayOfWeek; i > 0; i--) {
      const prevMonthDay = firstDayOfMonth.subtract(i, 'days')
      tempDaysArray.push({ formattedDay: prevMonthDay.format('D'), className: 'empty' })
    }

    for (let day = 1; day <= lastDayOfMonth.date(); day++) {
      const date = dayjs(new Date()).date(day)
      const isActiveDay = date.isSame(selectedDay, 'day')
      const className = isActiveDay ? 'active-day' : ''

      tempDaysArray.push({ formattedDay: date.format('D'), className })
    }

    return tempDaysArray
  }, [firstDayOfMonth, lastDayOfMonth, selectedDay])

  const formattedMonth = dayjs(currentDay).locale('pt-br').format('MMMM') + ' de ' + dayjs(currentDay).format('YYYY')
  const formattedMonthWithUppercase = formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)

  const changeCurrentDay = useCallback((date: Date) => setCurrentDay(date), [])

  const changeSelectedDay = useCallback((date: Date) => setSelectedDay(date), [])

  const upperCaseFirstLetter = useCallback((date: string) => date.charAt(0).toUpperCase() + date.slice(1), [])

  const extensiveSelectedDay = upperCaseFirstLetter(selectedDay.toLocaleDateString('pt-br', dateOptions))

  const itemsByDay = useMemo(() => {
    const itemsByDay = daysArray.map((day) => {
      return {
        day: day.formattedDay,
        items: [],
      }
    })

    if (data && data.scheduledMomentByProfessor && data.scheduledMomentByProfessor.items) {
      data.scheduledMomentByProfessor.items.forEach((item: Item) => {
        const scheduleDate = dayjs(item.dtSchedule)

        const dayWithLesson = itemsByDay.find((day) => day.day === scheduleDate.format('D'))

        if (dayWithLesson) {
          dayWithLesson.items.push(item)
        }
      })
    }

    return itemsByDay
    // eslint-disable-next-line
  }, [data, selectedDay])

  useEffect(() => {
    remove()
    refetch().then()
    // eslint-disable-next-line
  }, [selectedDay, refetch])

  const schedulePageProviderValues = useMemo(() => {
    return {
      currentDay,
      changeCurrentDay,
      selectedDay,
      changeSelectedDay,
      isLoading,
      itemsByDay,
      daysArray,
      data,
      isRefetching,
      extensiveSelectedDay,
      daysOfWeekEN,
      formattedMonthWithUppercase,
    }
  }, [
    currentDay,
    formattedMonthWithUppercase,
    itemsByDay,
    changeCurrentDay,
    selectedDay,
    changeSelectedDay,
    daysOfWeekEN,
    daysArray,
    isLoading,
    data,
    isRefetching,
    extensiveSelectedDay,
  ])

  return (
    <ScheduleByMonthPageContext.Provider value={schedulePageProviderValues}>
      {children}
    </ScheduleByMonthPageContext.Provider>
  )
}

const useScheduleByMonthPageContext = () => useContext(ScheduleByMonthPageContext)

export { ScheduleByMonthPageProvider, useScheduleByMonthPageContext }
