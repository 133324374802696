import styled, { css } from 'styled-components'

import theme from '~/styles/theme'

interface IButton {
  enabled: boolean
}

interface IContainerProps {
  fullScreen: boolean
}

export const Container = styled.div<IContainerProps>`
  align-items: center;
  justify-content: space-around;
  display: flex;
  width: ${({ fullScreen }) => (fullScreen ? '100%' : '390px')};
  height: ${({ fullScreen }) => (fullScreen ? '100%' : 'auto')};
  gap: 8px;
  padding: 0px;
  top: ${({ fullScreen }) => (fullScreen ? '0' : 'auto')};
  left: ${({ fullScreen }) => (fullScreen ? '0' : 'auto')};
  cursor: pointer;

  ${({ fullScreen }) =>
    fullScreen &&
    css`
      button {
        z-index: 10000;
        width: 32px;
        height: 32px;
      }
    `}
`

export const Content = styled.ul<IContainerProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const ThumbnailImage = styled.img<{ selected: boolean }>`
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  border: ${({ selected }) => (selected ? `3px solid ${theme.colors.actionPure}` : 'none')};
  border-radius: 4px;
`

export const Media = styled.li<IContainerProps>`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  width: ${({ fullScreen }) => (fullScreen ? '100%' : '329.5px;')};
  height: ${({ fullScreen }) => (fullScreen ? '100%' : 'auto')};
  gap: 8px;
  top: 60px;
  flex-direction: column;
`

export const Button = styled.button<IButton>`
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'normal')};
  background-color: ${({ theme, enabled }) => (enabled ? theme.colors.actionPure : theme.colors.midMedium)};
  align-items: center;
  border-radius: 50%;
  border: none;
  width: 24px;
  height: 24px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.highPure};
  justify-content: center;
  padding: 0;
  position: sticky;

  transition: all 0.5s;

  &:hover {
    background-color: ${({ enabled }) => (enabled ? theme.colors.actionDark : theme.colors.midMedium)};
    box-shadow: ${theme.shadows['shadow-level-3']};
  }
`
export const ButtonCloseModal = styled.button<IContainerProps>`
  z-index: 99999;
  color: ${({ theme }) => theme.colors.highPure};
  cursor: pointer;
  font-size: 18px;
`
export const ContainerVideo = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.lowLight};
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;
`
export const IconPlay = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #00000070;
  color: ${({ theme }) => theme.colors.highPure};
  margin: 0 auto;
  border-radius: 15px;
  position: absolute;
  z-index: 2;
  justify-content: center;
  align-items: center;
`
export const Video = styled.video`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`
export const Image = styled.div<{ src: string }>`
  width: 40px;
  height: 40px;
  background-image: url(${({ src }) => `${src}`});
  background-size: cover;
  border-radius: 8px;
`
