import { useEffect, useRef, useState } from 'react'

import { UilArrowDown } from '@iconscout/react-unicons'
import parse from 'html-react-parser'

import { DividerComponent } from '~/components'

import CardCarousel from '~/containers/private/Fund1/Planning/Carrousell/CardCarousel'

import { IAccordionOrientationsComponentProps } from './AccordionOrientations.interfaces'
import * as S from './AccordionOrientations.styles'

export const AccordionOrientationsComponent = ({
  title,
  type,
  txOrientationCode,
  supportReference,
}: IAccordionOrientationsComponentProps) => {
  const [maxHeight, setMaxHeight] = useState(0)
  const cardRef = useRef<Array<HTMLLIElement | null>>([])

  useEffect(() => {
    const heights = cardRef.current.map((ref) => ref?.clientHeight)
    if (heights && heights.length > 0) {
      setMaxHeight(Math.max(...(heights.filter((height) => height !== undefined) as number[])))
    }
  }, [])

  return (
    <S.AccordionContainer>
      <S.AccordionSummaryContent
        expandIcon={
          <S.CircleIcon>
            <UilArrowDown />
          </S.CircleIcon>
        }
      >
        <S.Title variant="caption" type={type}>
          {title}
        </S.Title>
      </S.AccordionSummaryContent>

      <S.AccordionDetailsContent>
        <S.ContentParagraphs>
          <S.Paragraph>
            <S.TextParagraph>{parse(String(txOrientationCode))}</S.TextParagraph>
          </S.Paragraph>
        </S.ContentParagraphs>

        {supportReference && supportReference?.length > 0 && (
          <>
            <DividerComponent />

            <S.Title variant="caption" type={type}>
              Referências de Apoio
            </S.Title>

            <S.ContentCards className={'styled-scroll'}>
              <CardCarousel key={0} items={supportReference} type={type} />
            </S.ContentCards>
          </>
        )}
      </S.AccordionDetailsContent>
    </S.AccordionContainer>
  )
}
