import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'unicons-line';
    src: url("../../src/fonts/unicons-line.eot") format('embedded-opentype');
    src: url('../../src/fonts/unicons-line.eot') format('embedded-opentype'), url('../../src/fonts/unicons-line.woff2') format('woff2'),
    url("../../src/fonts/unicons-line.woff") format('woff'), url('../../src/fonts/unicons-line.ttf') format('truetype'),
    url('../../src/fonts/unicons-line.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  body {
    background-color: ${({ theme }) => theme.colors.midLight};
    font-family: 'Inter', sans-serif !important;
    color: ${({ theme }) => theme.colors.lowDark};
  }

  .MuiTypography-root {
    font-family: 'Inter', sans-serif !important;
  }

  .alert-refresh {
    position: fixed;
    bottom: 0;
    padding: 15px 0;
    cursor: pointer;
    width: 100%;
    border: 0;
    box-shadow: none;
    text-align: center;
    z-index: 9999;
    color: white;
    background-color: #8C22BC;
    @media (max-width: 700px) {
      top: 0;
      bottom: unset;
    }
  }

  .ScrollApp {
    overflow-y: auto;
    height: 100vh;
  }

  .styled-scroll {
    ::-webkit-scrollbar {
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #CCCCCC;
      border: 1px solid #CCCCCC;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #aaa;
      width: 4px;
    }
  }


  .styled-scroll-s {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #CCCCCC;
      border: 1px solid #CCCCCC;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #aaa;
      width: 4px;
    }
  }


  .styled-scroll-xs {
    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #CCCCCC;
      border: 1px solid #CCCCCC;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #aaa;
      width: 2px;
    }
  }

`
