import { useState } from 'react'

import { UilArrowRight, UilBookReader, UilCheck } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { BackNavigationComponent, ButtonComponent, ChipComponent, SkeletonComponent } from '~/components'
import TagClass from '~/components/Cards/TagClass/TagClass'

import { mapCoStage } from '~/validations/mapCoStage'
import { truncateText } from '~/validations/truncateText'

import { IComponentProps } from '~/interfaces/Component'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  loading,
  path,
  title,
  components,
  description,
  actionOpenModalBNCC,
  actionSelectTrail,
  txSchoolGradeName,
  txGrade,
  txClassShift,
  coStage,
}: IHeaderComponentProps) => {
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  const [popoverContent, setPopoverContent] = useState<string[]>([])
  const [showPopover, setShowPopover] = useState<boolean>(false)

  const handleMouseEnter = (filteredComponents: IComponentProps[]) => {
    const content = filteredComponents.map((component: IComponentProps) => component.txComponent ?? '')
    setPopoverContent(content)
    setShowPopover(true)
  }

  const handleMouseLeave = () => {
    setShowPopover(false)
  }

  const handleNavigationToBncc = () => actionOpenModalBNCC()

  return (
    <S.HeaderContainer>
      <>
        <S.WrapperBetween>
          <BackNavigationComponent name={'Todas as Trilhas'} path={path} />
        </S.WrapperBetween>

        <S.Wrapper>
          {loading ? <SkeletonComponent variant="circular" height={40} width={40} /> : <></>}

          <S.Title variant="h1">{loading ? <SkeletonComponent width={200} /> : title}</S.Title>
        </S.Wrapper>

        <S.Wrapper>
          {loading ? (
            <>
              <SkeletonComponent variant="rounded" height={50} width={'80%'} />
            </>
          ) : (
            <TagClass
              variant={'default'}
              texts={[
                txClassShift as string,
                mapCoStage(coStage!),
                txGrade as string,
                truncateText(txSchoolGradeName as string, 10),
              ]}
            />
          )}
          <S.Wrapper>
            {loading ? (
              <>
                {Object.keys(new Array(1).fill(null)).map((_, index: number) => (
                  <SkeletonComponent key={index} variant="rounded" height={26} width={100} />
                ))}
              </>
            ) : (
              <S.ComponentContentPopover
                onMouseEnter={() => handleMouseEnter(components)}
                onMouseLeave={handleMouseLeave}
              >
                {components?.map((component: IComponentProps, index: number) => {
                  if (component.coComponentType === 'PRI') {
                    return (
                      <>
                        <ChipComponent
                          key={index}
                          label={
                            (component.txComponent.length ?? 0) > 25
                              ? component.txComponent.substring(0, 25) + '...'
                              : component.txComponent
                          }
                          count={components.length - 1}
                          type="normal"
                          isSametag
                        />
                        {showPopover && (
                          <S.ContainerPopover>
                            <S.TitleTooltip>Componentes dessa aula:</S.TitleTooltip>

                            <S.ContentTooltip>
                              {popoverContent?.map((content, index) => (
                                <>
                                  <S.TextTooltip
                                    key={index}
                                    className={index === popoverContent?.length - 1 ? 'last-item' : ''}
                                  >
                                    {index === 0 ? '' : index === popoverContent?.length - 1 ? ' e ' : ', '}
                                    {content}
                                  </S.TextTooltip>
                                </>
                              ))}
                            </S.ContentTooltip>
                            {isMobile ? (
                              <Tooltip title="Acessar Integração com o Currículo">
                                <S.ButtonIcon onClick={() => handleNavigationToBncc()}>
                                  <UilBookReader size={16} color="#0095ff" />
                                </S.ButtonIcon>
                              </Tooltip>
                            ) : (
                              <ButtonComponent
                                text="Acessar Integração com o Currículo"
                                variant="outline"
                                size="small"
                                iconEnd={<UilArrowRight size="18" />}
                                fontWeight="normal"
                                onClick={() => handleNavigationToBncc()}
                              />
                            )}
                          </S.ContainerPopover>
                        )}
                      </>
                    )
                  }
                })}
              </S.ComponentContentPopover>
            )}
          </S.Wrapper>
        </S.Wrapper>
        <S.DividerComponent />

        {!loading && (
          <>
            {isMobile ? (
              <ButtonComponent
                text="Escolher essa trilha"
                size="small"
                variant="solid"
                iconEnd={<UilCheck />}
                onClick={actionSelectTrail}
              />
            ) : (
              <ButtonComponent
                text="Escolher essa trilha"
                size="large"
                variant="solid"
                iconEnd={<UilCheck />}
                onClick={actionSelectTrail}
              />
            )}
          </>
        )}
      </>
    </S.HeaderContainer>
  )
}
