import { Tooltip } from '@mui/material'

import { renderStatusText } from '~/validations/renderStatusText'

import * as S from './TooltipStatus.styles'

export const TooltipStatusComponent = ({ status, children }: TooltipStatusComponentProps) => {
  return (
    <S.TooltipContainerHover>
      <S.TooltipContainer>
        <Tooltip
          title={renderStatusText(status)}
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '12px',
                padding: '4px 8px',
                fontWeight: 'bold',
              },
            },
          }}
          arrow
          placement={'right'}
        >
          {children}
        </Tooltip>
      </S.TooltipContainer>
    </S.TooltipContainerHover>
  )
}

export default TooltipStatusComponent
