import ReactDOM from 'react-dom/client'

import * as Sentry from '@sentry/react'
import userflow from 'userflow.js'

import App from './App'

userflow.init('ct_kd74zoogbzfx7nxwqbs3fxbd6a')

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Sentry.ErrorBoundary>
    <App />
  </Sentry.ErrorBoundary>,
)
