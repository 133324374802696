import { Box, Tooltip } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { ITrailStatusBarStyle } from '~/components/Bars/TrailStatusBar/TrailStatusBar.interfaces'

import theme from '~/styles/theme'

export const StatusBarContainer = styledMUI(Box)(() => ({
  display: 'flex',
  backgroundColor: theme.colors.highPure,
  color: theme.colors.midDark,
  border: `1px solid ${theme.colors.midDark}`,
  fontSize: '14px',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '50px',
  padding: '4px',
}))

export const StatusInfo = styledMUI(Box)(({ isActive, statusColor }: ITrailStatusBarStyle) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  padding: '4px 12px',
  maxWidth: isActive ? 'fit-content' : '100px',
  width: '100%',
  backgroundColor: isActive ? statusColor : 'unset',
  color: isActive ? theme.colors.highPure : 'inherit',
  borderRadius: '50px',

  '& span, & strong': {
    whiteSpace: 'nowrap',
  },

  '& span:not(:last-child), & strong': {
    padding: '0 8px 0 0',
    borderRight: isActive && `1px solid ${theme.colors.midPure}`,
  },
}))

export const StatusBarTooltip = styledMUI(Tooltip)(() => ({}))
