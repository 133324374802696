import { Box, Card, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const CardContainer = styledMUI(Card)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F9FAFC',
  padding: '16px',
  gap: '8px',
  borderRadius: '8px',
  boxShadow: 'none',
  '@media (max-width: 600px)': {
    height: '100%',
    width: '100%',
  },
}))

export const WrapperBetween = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media (max-width: 600px)': {
    height: '100%',
    display: 'grid',
    justifyContent: 'normal',
  },
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const WrapperBottom = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const WrapperChip = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    display: 'flex',
    width: '100%',
  },
}))

export const WrapperOverflow = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  overflowY: 'auto',
}))

export const WrapperMobile = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    width: '100%',
    display: 'grid',
    maxHeight: '100%',
  },
}))

export const WrapperIcons = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const Title = styledMUI(Typography)(() => ({
  maxWidth: '100%',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  color: '#000',
  '@media (max-width: 600px)': {
    maxHeight: '100%',
    lineHeight: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    marginBottom: '10px',
  },
}))

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  border: '1px solid #0095FF',
  borderRadius: '50px',
  padding: 0,
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))
