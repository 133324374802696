import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { UilArrowRight, UilEye } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'
import { gaEvents } from '~/events'
import { useTheme } from 'styled-components'

import { useAuth } from '~/_context/Auth'

import { AvatarComponent, ButtonComponent, ChipComponent, DividerComponent, SkeletonComponent } from '~/components'

import { getFormattedLabelWithDay } from '~/validations/formattLabelWithDay'
import { renderLabelBtn, renderStatusText } from '~/validations/renderStatusText'
import { statusColor } from '~/validations/statusColorsEM'
import { statusIcon } from '~/validations/statusIcon'
import { truncateText } from '~/validations/truncateText'

import { IComponentProps } from '~/interfaces/Component'

import { CardTrailProps } from './CardTrail.interfaces'
import * as S from './CardTrail.styles'

export const CardTrailComponent = ({
  description,
  coMomentStatus,
  dtSchedule,
  idClass,
  index,
  professor,
  idMoment,
  title,
  openModal,
  variant,
  loading,
  components,
  major,
  type = 'default',
  isTrail,
}: CardTrailProps) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const location = useLocation()
  const { user } = useAuth()
  const isSameUser = user?.id_user === professor?.idUserProfessor
  const removePrincipal = components?.filter((item: { coComponentType: string }) => item.coComponentType === 'PRI')
  const componentsFilter = components?.filter((item) => item.txComponent !== removePrincipal[0]?.txComponent)

  const handleGoToProject = () => {
    navigate(`/class/${idClass}/project/${idMoment}`, { state: { from: location.pathname } })
  }
  const handleGoToLesson = () => {
    navigate(`/class/${idClass}/lesson/${idMoment}`, { state: { from: location.pathname } })
  }

  return (
    <>
      {variant === 'not-generated' || variant === 'generated' ? (
        <S.CardProject key={idMoment} bgColor={statusColor(coMomentStatus ?? '').bgColor} isTrail={isTrail || false}>
          <S.ContainerTime>
            <S.CardIndex color={statusColor(coMomentStatus ?? '').indexColor}>{index}</S.CardIndex>
            <S.CardTime isTrail={isTrail || false} color={statusColor(coMomentStatus ?? '').textColor}>
              {getFormattedLabelWithDay(dtSchedule ?? '')}
            </S.CardTime>
          </S.ContainerTime>
          {!isTrail && (
            <>
              <DividerComponent color={statusColor(coMomentStatus ?? '').indexColor} />
            </>
          )}
          <S.InfoContainer>
            <S.CardStatus color={statusColor(coMomentStatus ?? '').textColor}>
              <S.TextSpan>{statusIcon(coMomentStatus ?? '')}</S.TextSpan>
              <p>{renderStatusText(coMomentStatus ?? '')}</p>
            </S.CardStatus>
            <Tooltip title={title} placement="top">
              <S.CardProjectTitle color={statusColor(coMomentStatus ?? '').titleColor}>
                {isTrail ? truncateText(title ?? '', 28) : title}
              </S.CardProjectTitle>
            </Tooltip>
            <Tooltip title={description} placement="top">
              <S.CardProjectContentDescription>
                <S.CardProjectDescription color={theme.colors.lowPure}>{description}</S.CardProjectDescription>
              </S.CardProjectContentDescription>
            </Tooltip>
            {isTrail && (
              <ButtonComponent
                variant={coMomentStatus === 'PEND' ? 'solid' : 'outline'}
                size={'xsmall'}
                fontWeight="normal"
                title={
                  coMomentStatus === 'AVPE' || coMomentStatus === 'PEND' || coMomentStatus === 'AUPE'
                    ? renderLabelBtn(coMomentStatus ?? '', false)
                    : 'Explorar aula'
                }
                text={
                  coMomentStatus === 'AVPE' || coMomentStatus === 'PEND' || coMomentStatus === 'AUPE'
                    ? renderLabelBtn(coMomentStatus ?? '', false)
                    : 'Explorar aula'
                }
                onClick={() => {
                  handleGoToLesson()
                  gaEvents.eventAccessLessonButton()
                }}
                iconEnd={<UilEye size={16} />}
              />
            )}
            {!isTrail && (
              <>
                {variant === 'not-generated' ? (
                  <>
                    {professor ? (
                      <>
                        <S.CardTeacher>
                          <AvatarComponent photoUrl={professor.txImagePath} label={professor.txName} size="medium" />
                          <S.TextSpan>{professor.txName}</S.TextSpan>
                        </S.CardTeacher>
                        <ButtonComponent
                          variant={isSameUser ? 'solid' : 'outline'}
                          size={'small'}
                          title={'Acessar aula'}
                          text={'Acessar aula'}
                          onClick={() => {
                            handleGoToProject()
                            gaEvents.eventAccessLessonButton()
                          }}
                          iconEnd={<UilArrowRight size={24} />}
                        />
                      </>
                    ) : (
                      <>
                        <ButtonComponent
                          variant={professor ? 'outline' : 'solid'}
                          size={'small'}
                          title={'Definir o professor'}
                          text={'Definir o professor'}
                          onClick={() => openModal && openModal()}
                          iconEnd={<UilArrowRight size={24} />}
                        />
                        <ButtonComponent
                          variant={professor ? 'solid' : 'outline'}
                          size={'small'}
                          title={'Acessar aula'}
                          text={'Acessar aula'}
                          onClick={() => {
                            handleGoToProject()
                            gaEvents.eventAccessLessonButton()
                          }}
                          iconEnd={<UilArrowRight size={24} />}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {professor ? (
                      <S.CardTeacher>
                        <AvatarComponent photoUrl={professor.txImagePath} label={professor.txName} size="medium" />
                        <span>{professor.txName}</span>
                      </S.CardTeacher>
                    ) : (
                      <S.CardTeacher>
                        <AvatarComponent size="medium" />
                        <span className={'disabled'}>Sem professor definido</span>
                      </S.CardTeacher>
                    )}
                    {!professor && (
                      <ButtonComponent
                        variant={'outline'}
                        size={'small'}
                        title={'Definir o professor'}
                        text={'Definir o professor'}
                        onClick={() => openModal && openModal()}
                        iconEnd={<UilArrowRight size={24} />}
                      />
                    )}
                    {professor && (
                      <ButtonComponent
                        variant={isSameUser ? 'solid' : 'outline'}
                        size={'small'}
                        title={'Acessar aula'}
                        text={'Acessar aula'}
                        onClick={() => {
                          handleGoToProject()
                          gaEvents.eventAccessLessonButton()
                        }}
                        iconEnd={<UilArrowRight size={24} />}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </S.InfoContainer>
        </S.CardProject>
      ) : (
        <S.CardContainer>
          {loading ? (
            <SkeletonComponent width={200} />
          ) : (
            <S.WrapperBetween>
              <S.Title variant="caption">
                Aula {index + 1}
                <b> {title ? title : 'Aula sem título'}</b>
              </S.Title>
              {type !== 'view' && (
                <S.TooltipLabel
                  title={'Acessar Aula'}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#000',
                        '& .MuiTooltip-arrow': {
                          color: 'common.black',
                        },
                      },
                    },
                  }}
                >
                  <S.ButtonIcon onClick={() => handleGoToProject()}>
                    <UilArrowRight size={20} color="#7C8189" />
                  </S.ButtonIcon>
                </S.TooltipLabel>
              )}
            </S.WrapperBetween>
          )}

          <S.Wrapper>
            {loading ? (
              <>
                {Object.keys(new Array(3).fill(null)).map((_, index: number) => (
                  <SkeletonComponent key={index} variant="rounded" width={70} />
                ))}
              </>
            ) : (
              <>
                {componentsFilter?.map((component: IComponentProps, index: number) => (
                  <ChipComponent key={index} label={component.txComponent} type="disabled" />
                ))}
              </>
            )}
          </S.Wrapper>

          <S.Description variant="body1">
            {loading ? <SkeletonComponent width={500} /> : description ? description : 'Sem descrição.'}
          </S.Description>
        </S.CardContainer>
      )}
    </>
  )
}
