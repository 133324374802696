import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'
import dayjs from 'dayjs'
import moment from 'moment'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { useStudentHistoryMoment } from '~/services/Students'

import { DividerComponent, MomentCardComponent, SkeletonComponent, ValidateDataSessionComponent } from '~/components'

import { useStudentDashboardPageContext } from '~/pages/private/StudentDashboard/context'

import { IDataHistoryProps } from './History.interfaces'
import * as S from './History.styles'

export const HistoryComponent = ({ studentId }: { studentId: number }) => {
  const { width } = useWindowDimensions()
  const widthSize = width || 0
  const { coGrade } = useStudentDashboardPageContext()

  const navigate = useNavigate()

  const [page, setPage] = useState(0)
  const [hasNext, setHasNext] = useState(false)
  const [dataHistoryPrev, setDataHistoryPrev] = useState<IDataHistoryProps[]>([])
  const [dataHistoryNext, setDataHistoryNext] = useState<IDataHistoryProps[]>([])

  const [allDataHistory, setAllDataHistory] = useState<IDataHistoryProps[]>([])

  const {
    data: dataHistory,
    isLoading: isLoadingdataHistory,
    refetch: refetchDataHistory,
    remove,
  } = useStudentHistoryMoment(Number(studentId), page + 1, coGrade)

  useEffect(() => {
    remove()
  }, [remove, studentId])

  useEffect(() => {
    const fetchData = async () => {
      if (!dataHistory?.data?.length) return

      const currentDate = moment().startOf('day')

      setAllDataHistory((prevData) => {
        const newData = dataHistory?.data?.filter((newItem: any) => {
          return !prevData.some(
            (prevItem: any) => prevItem.idMoment === newItem.idMoment && prevItem.coGrade === coGrade,
          )
        })
        return [...prevData, ...newData]
      })

      const prevData = dataHistory?.data?.filter(
        (momentHistory: any) =>
          moment(momentHistory.dtSchedule).isBefore(currentDate) ||
          (moment(momentHistory.dtSchedule).isSame(currentDate) &&
            momentHistory.coGrade === coGrade &&
            momentHistory.momentStatus.coMomentStatus === 'FINA'),
      )
      setDataHistoryPrev(prevData)

      const nextData = dataHistory?.data
        ?.filter(
          (momentHistory: any) =>
            moment(momentHistory.dtSchedule).isAfter(currentDate) ||
            (moment(momentHistory.dtSchedule).isSame(currentDate) &&
              momentHistory.coGrade === coGrade &&
              momentHistory.momentStatus.coMomentStatus !== 'FINA'),
        )
        .sort((a: any, b: any) => new Date(a.dtSchedule).getTime() - new Date(b.dtSchedule).getTime())
      setDataHistoryNext(nextData)
    }

    fetchData()
  }, [coGrade, dataHistory])

  useEffect(() => {
    const valueHasNext = dataHistory?.headers['x-pagination']?.length
      ? JSON.parse(dataHistory?.headers['x-pagination'])?.hasNext
      : ''
    setHasNext(valueHasNext)
  }, [dataHistory?.headers, page])

  const fetchMoreData = () => {
    setPage(page + 1)

    setTimeout(() => {
      refetchDataHistory().then()
    }, 200)
  }

  function checkDate(date: Date): string {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (date.toDateString() === today.toDateString()) {
      return 'Hoje'
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Ontem'
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return 'Amanhã'
    } else {
      return dayjs(date).format('DD/MM/YYYY')
    }
  }

  const redirectToCorrectRoute = (coStage: string, idMoment: string, classId: number) => {
    if (coStage) {
      if (coStage.includes('F2')) {
        return () => navigate(`/class/${classId}/project/${idMoment}`, { state: { from: '/home' } })
      } else if (coStage.includes('F1')) {
        return () => navigate(`/class/${classId}/lesson/${idMoment}`, { state: { from: '/home' } })
      } else if (coStage.includes('EM')) {
        return () => navigate(`/class/${classId}/project/${idMoment}`, { state: { from: '/home' } })
      } else if (coStage.includes('M1') || coStage.includes('M2')) {
        return () => navigate(`/class/${classId}/lesson/${idMoment}`, { state: { from: '/home' } })
      } else if (coStage.includes('M3') || coStage.includes('M4')) {
        return () => navigate(`/class/${classId}/project/${idMoment}`, { state: { from: '/home' } })
      } else if (coStage.includes('IN')) {
        return () => navigate(`/class/${classId}/lesson/${idMoment}`, { state: { from: '/home' } })
      } else {
        return null
      }
    }
  }

  return (
    <S.ContainerHistory>
      <>
        {isLoadingdataHistory ? (
          <Box display={'grid'} gap={4}>
            {Object.keys(new Array(3).fill(null)).map((_, index: number) => (
              <MomentCardComponent loading={isLoadingdataHistory} key={index} />
            ))}
          </Box>
        ) : dataHistoryNext?.length > 0 || dataHistoryPrev?.length > 0 ? (
          <InfiniteScroll
            dataLength={allDataHistory?.length}
            hasMore={hasNext}
            scrollThreshold={'500px'}
            next={fetchMoreData}
            loader={
              <S.LoaderCardContainer>
                <S.Wrapper>
                  <S.LoaderCardContent>
                    <SkeletonComponent variant="rounded" width={130} />
                    <SkeletonComponent width={120} />
                  </S.LoaderCardContent>
                  <SkeletonComponent variant="circular" height={24} width={24} />
                </S.Wrapper>
                <SkeletonComponent width={widthSize > 600 ? 400 : 100} />
                <S.Wrapper>
                  <SkeletonComponent width={widthSize > 600 ? 200 : 50} />
                </S.Wrapper>
              </S.LoaderCardContainer>
            }
          >
            <S.ContentHistory component="ul">
              {dataHistoryNext?.length > 0 && (
                <>
                  <S.TextLabel>Próximas aulas</S.TextLabel>
                  {dataHistoryNext?.map((historyMomentNext: any, index) => (
                    <MomentCardComponent
                      key={`moment-${historyMomentNext?.idMoment}-${index}`}
                      loading={isLoadingdataHistory}
                      nuOrder={historyMomentNext?.nuOrder}
                      cardbackgroundcolor="#FFF"
                      totalClass={Number(historyMomentNext?.nuTrackStages)}
                      momentTime={historyMomentNext?.dtSchedule}
                      action={redirectToCorrectRoute(
                        historyMomentNext?.coStage,
                        historyMomentNext?.idMoment,
                        historyMomentNext?.idClass,
                      )}
                      momentStatusCode={historyMomentNext?.momentStatus?.coMomentStatus as string}
                      momentStatusText={historyMomentNext?.momentStatus?.txMomentStatus as string}
                      momentTitle={historyMomentNext?.txTitle}
                      coGrade={historyMomentNext?.coGrade}
                      txTrackTitle={historyMomentNext?.txTrackTitle}
                      idClass={historyMomentNext?.idClass}
                      idMomentTrack={historyMomentNext?.idMomentTrack}
                      coStage={historyMomentNext?.coStage}
                      txGrade={historyMomentNext?.txGrade}
                      txClassName={historyMomentNext?.txClassName}
                      momentClassInfo={`${historyMomentNext?.txGrade} - ${historyMomentNext?.txClassName} - ${historyMomentNext?.coGrade}`}
                      chipOld={true}
                    />
                  ))}
                  <DividerComponent />
                </>
              )}

              {dataHistoryPrev?.length > 0 && (
                <>
                  <S.TextLabel>Aulas passadas</S.TextLabel>
                  {dataHistoryPrev?.map((historyMomentPrev: any, index) => (
                    <MomentCardComponent
                      key={`moment-${historyMomentPrev?.idMoment}-${index}`}
                      loading={isLoadingdataHistory}
                      cardbackgroundcolor="#FFF"
                      momentStatusCode={historyMomentPrev?.momentStatus?.coMomentStatus as string}
                      action={redirectToCorrectRoute(
                        historyMomentPrev?.coStage,
                        historyMomentPrev?.idMoment,
                        historyMomentPrev?.idClass,
                      )}
                      momentStatusText={historyMomentPrev?.momentStatus?.txMomentStatus as string}
                      momentTitle={historyMomentPrev?.txTitle}
                      momentClassInfo={`${historyMomentPrev?.txGrade} - ${historyMomentPrev?.txClassName}`}
                      coGrade={historyMomentPrev?.coGrade}
                      txTrackTitle={historyMomentPrev?.txTrackTitle}
                      idClass={historyMomentPrev?.idClass}
                      idMomentTrack={historyMomentPrev?.idMomentTrack}
                      coStage={historyMomentPrev?.coStage}
                      txGrade={historyMomentPrev?.txGrade}
                      txClassName={historyMomentPrev?.txClassName}
                      nuOrder={historyMomentPrev?.nuOrder}
                      chipOld={true}
                      totalClass={Number(historyMomentPrev?.nuTrackStages)}
                      momentTime={historyMomentPrev?.dtSchedule}
                    />
                  ))}
                </>
              )}
            </S.ContentHistory>
          </InfiniteScroll>
        ) : (
          <ValidateDataSessionComponent
            screen="class"
            title="Esse aluno ainda não possui histórico"
            description=" "
            space={-250}
          />
        )}
      </>
    </S.ContainerHistory>
  )
}
