import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const Container = styledMUI(Box)(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '24px',
  gap: '24px',
}))

export const ModalContentContainer = styledMUI(Box)(() => ({
  display: 'flex',
  backgroundColor: '#fff',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxHeight: '720px',
  gap: '24px',

  overflowY: 'auto',
  overflowX: 'hidden',
  borderRadius: '8px',

  '&::-webkit-scrollbar': {
    width: '5px',
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: '#CCCCCC',
    border: '1px solid #CCCCCC',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: '#aaa',
    width: '4px',
  },
}))

export const AvatarContainer = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  flexWrap: 'wrap',
  width: '100%',
  overflow: 'hidden',
}))

export const ContentBox = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '120%',
  color: `${theme.colors.actionPure}`,
}))

export const SmallView = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '16px',
  height: '500px',
  overflowY: 'auto',
  marginTop: 20,
}))

export const BtnClose = styledMUI(Box)(() => ({
  position: 'relative',
  color: `${theme.colors.lowPure}`,
  cursor: 'pointer',
  padding: 0,
}))

export const Divider = styledMUI(Box)(() => ({
  display: 'flex',
  width: '104.5%',
  height: '1px',
  backgroundColor: `${theme.colors.highDark}`,
  margin: '0px 0px 0px -24px;',
}))

export const CardStudent = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '8px',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: `${theme.colors.highLight}`,
  borderRadius: '8px',
  width: '32%',
  height: '90px',
  padding: '8px',
}))

export const TextStudent = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: `${theme.colors.lowPure}`,
}))
