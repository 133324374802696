import { Box, Card, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled, { css } from 'styled-components'

import theme from '~/styles/theme'

interface ICardContainerProps {
  isMobile: boolean
}

export const ContentList = styledMUI(Box)(() => ({
  backgroundColor: '#fff',
  padding: '24px',
  opacity: 1,
  animation: 'fadeIn 0.2s ease-out forwards',
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))

export const TitleRow = styledMUI(Card)(() => ({
  display: 'flex',
  marginBottom: '10px',
  marginTop: '20px',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  boxShadow: 'none',
}))

export const NuOrder = styledMUI(Card)(() => ({
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${theme.colors.midDark}`,
  color: 'white',
  fontSize: '12px',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '150%',
  color: '#000',
}))

export const VisibleContentList = styled(ContentList)({
  opacity: 1,
})
export const Label = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  marginTop: '20px',
  lineHeight: '120%',
  color: '#000',
}))

export const ContainerScroll = styled.div`
  overflow-y: auto;
  height: 100vh;
`

export const ContainerLesson = styled.div`
  padding: 24px;
  display: grid;
  gap: 16px;
  width: 100%;
`

export const Arrow = styledMUI(Box)<{ enabled: boolean }>(({ enabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: enabled ? '#ccc' : '#0095FF',
  width: '24px',
  height: '24px',
  color: '#fff',
  borderRadius: '50%',
}))

export const PutStart = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
  paddingBottom: '20px',
}))

export const CardSliderResumeContainer = styled.div<ICardContainerProps>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`

export const PutEnd = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '16px',
  paddingBottom: '20px',
}))

export const ContainerCardsList = styled.ul<{ displayMode: boolean }>`
  width: 100%;
  max-width: 100%;
  gap: 8px;
  padding: 16px 0;

  ${({ displayMode }) =>
    !displayMode
      ? css`
          display: flex;
          overflow: hidden;
          overflow-y: hidden;
        `
      : css`
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        `}
`

export const CardProject = styled.li`
  min-width: 260px;
  max-width: 260px;
  min-height: 366px;
  max-height: 366px;
  padding: 16px;
  list-style: none;
  border-radius: 8px;
  height: fit-content;
  background-color: #f9fafc;

  .divider {
    margin: 16px 0;
  }
`

export const ContainerTime = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
`

export const CardIndex = styled.div`
  min-width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  display: flex;
  border-radius: 24px;
  background-color: #bdc3cf;
`

export const InfoContainer = styled.div`
  display: grid;
  gap: 16px;
`

export const WrapperChip = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'flex-end',
  gap: '8px',
  '@media (max-width: 600px)': {
    display: 'flex',
    width: '100%',
  },
}))
export const CardProjectTitle = styled.h2`
  color: #000;
  font-weight: 700;
  font-size: 20px;
  min-height: 67px;
`

export const CardProjectDescription = styled.p`
  display: -webkit-box;
  font-size: 14px;
  line-height: 150%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
