import { Box, Chip, Typography, Button } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  padding: '94px 24px 24px',
  marginTop: '-70px',
  gap: '16px',
  borderBottom: '1px solid #E2E5EC',
  backgroundColor: '#fff',
  zIndex: '1',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const ChipLabel = styledMUI(Chip)(() => ({
  marginLeft: '10px',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '120%',
  color: '#000',
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#666',
}))

export const ContainerTrackClass = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #7c8189;
  line-height: 1;
  padding: 4px 8px;
  border-radius: 4px;

  span:last-child {
    border-right: none;
  }
`

export const ClassSpanInfo = styled.span`
  font-size: 14px;
  padding: 0 6px;
  color: #7c8189;
  border-right: 1px solid #e2e5ec;
  min-width: fit-content;
`

export const ContentCarousel = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  paddingBottom: '20px',
  overflowX: 'auto',
}))

export const Row = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  '@media (max-width: 600px)': {
    flexDirection: 'column-reverse',
    alignItems: 'baseline',
  },
}))

export const Column = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const VisualizeStudentsButton = styledMUI(Button)(() => ({
  fontSize: '12px',
  backgroundColor: 'transparent',
  color: `${theme.colors.actionPure}`,
  border: `1px solid ${theme.colors.actionPure}`,
  borderRadius: '50px',
  padding: '4px 8px',
  lineHeight: '1',
  height: '24px',
  textTransform: 'none',

  '&:hover': {
    borderColor: theme.colors.actionDark,
    backgroundColor: 'transparent',
    boxShadow: theme.shadows['shadow-level-3'],
    color: theme.colors.actionDark,
  },
}))
