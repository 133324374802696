import React, { useState } from 'react'

import { UilCalender, UilEditAlt, UilTimesCircle } from '@iconscout/react-unicons'
import { Box, Divider } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'

import { useAuth } from '~/_context/Auth'

import { Moment } from '~/services/Trails/types'

import { ButtonComponent, SkeletonComponent } from '~/components'
import { TimeLineLessonComponent } from '~/components/Modals/TimelineLesson/TimelineLesson'

import { formatDayOfWeek } from '~/validations/typeWeek'

import theme from '~/styles/theme'

import * as S from './Form.styles'

interface FormProps {
  dates: []
  title: string
  handleDateChange: (index: number, moment: Moment) => void
  handleDateChangeSchedule: (index: number, moment: Moment) => void
  disabledDate: () => void
  datesFormatted: string
  validateMoment: () => void
  type: string
  changedIndexes: any[]
}

const Form: React.FC<FormProps> = ({
  dates,
  disabledDate,
  datesFormatted,
  dataTrackSchedule,
  validateMoment,
  handleDateChange,
  handleDateChangeSchedule,
  handleLeave,
  handleRealize,
  isLoading,
  projectType,
  changedIndexes,
}) => {
  const { user } = useAuth()
  const [openPicker, setOpenPicker] = useState<number | null>(null)
  const MiniCard = ({ nuOrder, date, professor, project, moment, type }) => {
    const showActions =
      (professor && (professor.idUser === user?.id_user || professor.idUserProfessor === user?.id_user)) || !professor
    const isDisabled = validateMoment(moment)
    return (
      <S.MiniCardContainer>
        {project === 1 && type === 'schedule' ? (
          <S.TooltipLabel
            title={
              'Professor, já definimos as datas das próximas aulas, mas você pode alterá-las conforme achar necessário.'
            }
            placement="left"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#000',
                  '& .MuiTooltip-arrow': {
                    color: 'common.black',
                  },
                },
              },
            }}
          >
            <S.DateLabel>{date}</S.DateLabel>
          </S.TooltipLabel>
        ) : (
          <S.DateLabel>{date}</S.DateLabel>
        )}

        <S.DividerVertical orientation="vertical" />

        {professor ? (
          <>
            <S.AvatarDefault alt="Foto do Professor" src={professor?.txImagePath} />
            <S.TextInfoLabel>{professor?.txName}</S.TextInfoLabel>
          </>
        ) : (
          <>
            <S.AvatarDefault alt="Foto do Professor" src={user?.picture} />
            <S.TextInfoLabel>{user?.name}</S.TextInfoLabel>
          </>
        )}

        {showActions && (
          <>
            {' '}
            <S.ChipCustom>você</S.ChipCustom>
            <UilEditAlt
              data-testid="trail-edit-button"
              style={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
              size={16}
              color={isDisabled ? theme.colors.lowLight : theme.colors.actionPure}
              onClick={!isDisabled ? () => handleButtonClick(nuOrder) : undefined}
            />
            {project === 2 && (
              <UilTimesCircle
                data-testid="trail-desengage-button"
                style={{
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
                size={16}
                color={isDisabled ? theme.colors.lowLight : theme.colors.feedback.error.main}
                onClick={!isDisabled ? () => handleLeave(nuOrder) : undefined}
              />
            )}
          </>
        )}
      </S.MiniCardContainer>
    )
  }

  if (isLoading)
    return (
      <S.MainBox>
        <S.ListBox>
          <Box width={'100%'} display={'grid'}>
            <SkeletonComponent width={'98%'} height={750} variant={'rounded'} />
          </Box>
        </S.ListBox>
        <S.ListBox>
          <Box width={'100%'} display={'grid'}>
            <SkeletonComponent width={'100%'} height={750} variant={'rounded'} />
          </Box>
        </S.ListBox>
      </S.MainBox>
    )
  const renderDatePicker = (moment, index) => {
    const noProfessor = !!moment.professor || !!moment.idUserProfessor
    switch (true) {
      case !!moment.dtSchedule && moment.coMomentStatus !== 'AUPE' && noProfessor:
        return (
          <>
            <MiniCard
              project={moment.project}
              nuOrder={moment?.nuOrder}
              professor={moment?.professor}
              type={moment?.type}
              moment={moment}
              date={dayjs(moment.dtSchedule).format('DD/MM')}
            />
            <DesktopDatePicker
              open={openPicker === moment?.nuOrder}
              onClose={() => setOpenPicker(null)}
              inputFormat="DD/MM"
              value={moment.dtSchedule}
              disablePast
              disabled={validateMoment(moment)}
              dayOfWeekFormatter={formatDayOfWeek}
              shouldDisableDate={(date) => disabledDate(date as Dayjs, moment?.nuOrder)}
              onChange={
                ['edit'].includes(moment.type)
                  ? (newDate) => handleDateChange({ index, newDate })
                  : (newDate) => handleDateChangeSchedule(index, newDate)
              }
              renderInput={({ inputRef, inputProps }) => <div ref={inputRef} {...inputProps} />}
            />
          </>
        )

      case (!moment.dtSchedule || !noProfessor) && moment.coMomentStatus !== 'AUPE':
        return (
          <>
            <ButtonComponent
              iconEnd={<UilCalender size={16} />}
              onClick={() => handleButtonClick(moment?.nuOrder)}
              variant="solid"
              fontWeight="normal"
              text="Definir data"
              data-testid="trail-schedule-button"
              size="small2"
            />
            <DesktopDatePicker
              open={openPicker === moment?.nuOrder}
              onClose={() => setOpenPicker(null)}
              inputFormat="DD/MM"
              value={moment.dtSchedule}
              disablePast
              dayOfWeekFormatter={formatDayOfWeek}
              shouldDisableDate={(date) => disabledDate(date as Dayjs, moment?.nuOrder)}
              onChange={
                ['edit'].includes(moment.type)
                  ? (newDate) => handleDateChange({ index, newDate })
                  : (newDate) => handleDateChangeSchedule(index, newDate)
              }
              renderInput={({ inputRef, inputProps }) => <div ref={inputRef} {...inputProps} />}
            />
          </>
        )

      case !!moment.dtSchedule && moment.coMomentStatus === 'AUPE':
        if (
          (user?.id_user === moment?.professor?.idUserProfessor ||
            user?.id_user === moment?.idUserProfessor ||
            user?.id_user === moment?.idUser ||
            user?.id_user === moment?.professor?.idUser) &&
          !changedIndexes.includes(index)
        ) {
          return (
            <>
              <S.BtnContainer>
                <ButtonComponent
                  iconEnd={<UilCalender size={16} />}
                  onClick={() => handleButtonClick(moment?.nuOrder)}
                  variant="outline"
                  fontWeight="normal"
                  data-testid="trail-remark-button"
                  text="Remarcar"
                  size="xsmall"
                />
                <DesktopDatePicker
                  open={openPicker === moment?.nuOrder}
                  onClose={() => setOpenPicker(null)}
                  inputFormat="DD/MM"
                  value={moment.dtSchedule}
                  disablePast
                  dayOfWeekFormatter={formatDayOfWeek}
                  shouldDisableDate={(date) => disabledDate(date as Dayjs, moment?.nuOrder)}
                  onChange={
                    ['edit'].includes(moment.type)
                      ? (newDate) => handleDateChange({ index, newDate })
                      : (newDate) => handleDateChangeSchedule(index, newDate)
                  }
                  renderInput={({ inputRef, inputProps }) => <div ref={inputRef} {...inputProps} />}
                />
                <ButtonComponent
                  iconEnd={<UilCalender size={16} />}
                  onClick={() => handleRealize(moment?.nuOrder, moment?.dtSchedule)}
                  variant="solid"
                  data-testid="trail-remark-done-button"
                  fontWeight="normal"
                  text="Marcar como Realizada"
                  size="xsmall"
                />
              </S.BtnContainer>
            </>
          )
        } else {
          return (
            <>
              <MiniCard
                project={moment.project}
                nuOrder={moment?.nuOrder}
                professor={moment?.professor}
                moment={moment}
                type={moment?.type}
                date={dayjs(moment.dtSchedule).format('DD/MM')}
              />
              <DesktopDatePicker
                open={openPicker === moment?.nuOrder}
                onClose={() => setOpenPicker(null)}
                inputFormat="DD/MM"
                value={moment.dtSchedule}
                disablePast
                disabled={validateMoment(moment)}
                dayOfWeekFormatter={formatDayOfWeek}
                shouldDisableDate={(date) => disabledDate(date as Dayjs, moment?.nuOrder)}
                onChange={
                  ['edit'].includes(moment.type)
                    ? (newDate) => handleDateChange({ index, newDate })
                    : (newDate) => handleDateChangeSchedule(index, newDate)
                }
                renderInput={({ inputRef, inputProps }) => <div ref={inputRef} {...inputProps} />}
              />
            </>
          )
        }

      default:
        return null
    }
  }

  const handleButtonClick = (nuOrder: number) => {
    setOpenPicker(nuOrder)
  }

  return (
    <>
      <S.Container>
        <S.MainBox>
          <S.ListBox>
            <S.Title>Defina a data de uma ou mais aulas que você deseja escolher:</S.Title>

            {dates?.map((moment, index) => (
              <>
                <S.BoxBetween>
                  <S.RowContainer key={index}>
                    <S.TitleWrapper>
                      <S.WrapperNumber>
                        <S.NumberBox>{moment?.nuOrder}</S.NumberBox>
                      </S.WrapperNumber>
                      <S.TitleCardProject>{moment?.txTitle}</S.TitleCardProject>
                    </S.TitleWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                      {renderDatePicker(moment, index)}
                    </LocalizationProvider>
                  </S.RowContainer>
                </S.BoxBetween>
                {index !== dates.length - 1 && <Divider />}
              </>
            ))}
          </S.ListBox>
          <S.ListBox>
            {datesFormatted && (
              <TimeLineLessonComponent
                projectType={projectType}
                dates={datesFormatted}
                dataTrackSchedule={dataTrackSchedule}
                handleDateChange={handleDateChangeSchedule}
                disabledDate={disabledDate}
              />
            )}
          </S.ListBox>
        </S.MainBox>
      </S.Container>
    </>
  )
}

export default Form
