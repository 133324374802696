import React from 'react'

import { UilBook, UilCalender } from '@iconscout/react-unicons'
import { SelectChangeEvent, TextField, Tooltip } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'

import { useAuth } from '~/_context/Auth'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarComponent } from '~/components/Basic/Avatar/Avatar'
import { TextReductionComponent } from '~/components/Modals/AccordinModal/TextReduction'
import { useScheduModalContext } from '~/components/Modals/ScheduleModal/ScheduleContext'
import { TimeLineLessonComponent } from '~/components/Modals/TimelineLesson/TimelineLesson'

import { IDataClassInfosProps } from '~/pages/private/Trails/TrailDetailsProjectEM/interfaces'

import { formatDayOfWeek } from '~/validations/typeWeek'

import { Moment } from '../../ScheduleModal.interfaces'
import * as S from './FormEM.styles'

interface FormEMProps {
  dates: []
  title: string
  dataClassInfos: IDataClassInfosProps
  handleDateChange: (date: object) => void
  disabledDate: (date: object) => void
  handleSelectFormChange: () => void
  datesFormatted: string
  type: string
  validateMoment: () => void
}

const FormEM: React.FC<FormEMProps> = ({
  dates,
  title,
  dataClassInfos,
  handleDateChange,
  disabledDate,
  handleSelectFormChange,
  datesFormatted,
  validateMoment,
}) => {
  const { user } = useAuth()
  const idUser = user?.id_user
  const nameUser = user?.name
  const photoUser = user?.picture
  const newAvatar = localStorage.getItem('@LEKTO:avatar')
  const { idTrack, open } = useScheduModalContext()
  const { width } = useWindowDimensions()
  const isMobile = Number(width) < 600

  const getFirstName = (fullName: string): string => {
    const repart = fullName.split(' ')
    return repart[0]
  }

  return (
    <>
      <S.Container>
        <S.ContentInfo>
          <S.Content>
            {dates && !isMobile ? (
              <>
                <S.TitleCardProject>
                  <S.CardTitle>
                    <S.IconCircle>
                      <UilBook size={24} color="#0095FF" />
                    </S.IconCircle>
                    <S.TextTitleTrilhas variant="h3">
                      <TextReductionComponent text={title} maxLength={85} />
                    </S.TextTitleTrilhas>
                  </S.CardTitle>
                </S.TitleCardProject>
                <S.ContainerTrackClass>
                  <S.ClassSpanInfo>
                    {dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.txName}
                  </S.ClassSpanInfo>
                  <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.classShift?.txClassShift}</S.ClassSpanInfo>
                  <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.txGrade}</S.ClassSpanInfo>
                  <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.txName}</S.ClassSpanInfo>
                </S.ContainerTrackClass>
                <S.BoxOverflow className="styled-scroll-s">
                  {dates
                    .sort((a, b) => a.nuOrder - b.nuOrder)
                    ?.map((moment: Moment, index: number) => {
                      const lastIndex = dates.length
                      return (
                        <>
                          <S.WrapperDivider key={moment?.lessonTrackGroup?.nuOrder}>
                            <S.WrapperNumber>
                              <S.NumberBox>{moment.nuOrder}</S.NumberBox>
                            </S.WrapperNumber>
                            <S.WrapperText>
                              <Tooltip title={moment.title ? moment.title : ''}>
                                <S.TextClass variant="h1">
                                  {(moment.title.length ?? 0) > 30
                                    ? moment.title.substring(0, 30) + '...'
                                    : moment.title}
                                </S.TextClass>
                              </Tooltip>
                            </S.WrapperText>
                            <S.Wrapper>
                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                                <S.DatePickerStyle
                                  value={moment.dtSchedule || 'Selecione um horário'}
                                  onChange={(newDate) => handleDateChange({ index, newDate: newDate as string })}
                                  inputFormat="DD/MM"
                                  disabled={validateMoment(moment)}
                                  dayOfWeekFormatter={formatDayOfWeek}
                                  shouldDisableDate={(date) => disabledDate(date as Dayjs, index + 1)}
                                  components={{
                                    OpenPickerIcon: UilCalender,
                                  }}
                                  sx={{
                                    marginTop: '0.5px',
                                    width: '108px',
                                  }}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                        size="small"
                                        error={false}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: 'Data',
                                        }}
                                      />
                                    )
                                  }}
                                />
                              </LocalizationProvider>
                            </S.Wrapper>
                            <S.Wrapper>
                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                                <S.SelectTeacher>
                                  {moment.idProfessor && moment.idProfessor !== idUser && (
                                    <S.MenuOtherTeacher value={moment.idProfessor}>
                                      <S.WrapperTeacher>
                                        {!moment.idProfessor && (
                                          <AvatarComponent
                                            size={'small'}
                                            label={moment.professor ? moment.professor?.txName : ''}
                                            photoUrl={moment.professor?.txImagePath}
                                          />
                                        )}

                                        {moment.idProfessor && (
                                          <AvatarComponent
                                            size={'small'}
                                            label={moment.professor ? moment.professor?.txName : ''}
                                            photoUrl={moment.professor?.txImagePath}
                                          />
                                        )}
                                        <S.NameProfessor>
                                          {!moment.idProfessor ? '' : moment.professor?.txName}
                                        </S.NameProfessor>
                                      </S.WrapperTeacher>
                                    </S.MenuOtherTeacher>
                                  )}

                                  {!moment.idProfessor && (
                                    <S.MenuTeacher value={moment.idProfessor}>
                                      <S.WrapperTeacherSelect>
                                        {!moment.idProfessor && (
                                          <S.SwitchTeacher
                                            onChange={(e: SelectChangeEvent<HTMLSelectElement>) =>
                                              handleSelectFormChange(Number(idUser), moment.idMoment, index)
                                            }
                                          />
                                        )}

                                        {moment.idProfessor && (
                                          <AvatarComponent
                                            size={'small'}
                                            label={moment.professor ? moment.professor?.txName : ''}
                                            photoUrl={moment.professor?.txImagePath}
                                          />
                                        )}
                                        <S.NameProfessor>
                                          {!moment.idProfessor ? 'Selecionar aula' : moment.professor?.txName}
                                        </S.NameProfessor>
                                      </S.WrapperTeacherSelect>
                                    </S.MenuTeacher>
                                  )}

                                  {moment.idProfessor && moment.idProfessor === idUser && (
                                    <S.MenuTeacher
                                      disabled={['FINA', 'AVPE'].includes(moment.coMomentStatus)}
                                      value={moment.idProfessor}
                                    >
                                      <S.WrapperTeacherSelect>
                                        {moment.idProfessor && (
                                          <S.SwitchTeacher
                                            color="primary"
                                            onChange={() => handleSelectFormChange(idUser, moment.idMoment, index)}
                                            checked={true}
                                            sx={{
                                              '& .MuiSwitch-track': {
                                                backgroundColor: '#f50057',
                                              },
                                              '& .MuiSwitch-switchBase': {
                                                color: '#f50057',
                                                '&.Mui-disabled': {
                                                  color: '#e886a9',
                                                },
                                                '&.Mui-checked': {
                                                  color: '#0095ff',
                                                  '& + .MuiSwitch-track': {
                                                    backgroundColor: '#0095ff',
                                                  },
                                                },
                                              },
                                              '& .MuiSwitch-primary': {
                                                '&.Mui-checked': {
                                                  color: '#0095ff',
                                                  '& + .MuiSwitch-track': {
                                                    backgroundColor: '#0095ff',
                                                  },
                                                },
                                              },
                                            }}
                                          />
                                        )}

                                        {moment.idProfessor && (
                                          <AvatarComponent
                                            size={'small'}
                                            label={moment.professor ? moment.professor?.txName : ''}
                                            photoUrl={moment.professor?.txImagePath || photoUser || newAvatar}
                                          />
                                        )}
                                        <S.NameProfessorBold>
                                          {getFirstName(moment.professor?.txName || nameUser)}
                                        </S.NameProfessorBold>
                                        <S.ChipProfessor>você</S.ChipProfessor>
                                      </S.WrapperTeacherSelect>
                                    </S.MenuTeacher>
                                  )}
                                </S.SelectTeacher>
                              </LocalizationProvider>
                            </S.Wrapper>
                          </S.WrapperDivider>
                          {index + 1 !== lastIndex && <S.DividerLine />}
                        </>
                      )
                    })}
                </S.BoxOverflow>
              </>
            ) : (
              <>
                <S.BoxOverflowMobile className="styled-scroll-xs">
                  <S.TitleCardProject>
                    <S.CardTitleMobile>
                      <S.IconCircle>
                        <UilBook size={24} color="#0095FF" />
                      </S.IconCircle>
                      <S.TextTitleTrilhas variant="h3">
                        <TextReductionComponent text={title} maxLength={85} />
                      </S.TextTitleTrilhas>
                    </S.CardTitleMobile>
                  </S.TitleCardProject>

                  <S.ContainerTrackClass>
                    <S.ClassSpanInfo>
                      {dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.txName}
                    </S.ClassSpanInfo>
                    <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.classShift?.txClassShift}</S.ClassSpanInfo>
                    <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.txGrade}</S.ClassSpanInfo>
                    <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.txName}</S.ClassSpanInfo>
                  </S.ContainerTrackClass>
                  {dates?.map((moment: Moment, index: number) => {
                    return (
                      <>
                        <S.WrapperContainer>
                          <S.WrapperDivider key={moment?.lessonTrackGroup?.nuOrder}>
                            <S.WrapperNumber>
                              <S.NumberBox>{index + 1}</S.NumberBox>
                              <S.TextClass variant="h1">{moment.title}</S.TextClass>
                            </S.WrapperNumber>
                          </S.WrapperDivider>
                          <S.WrapperMobile>
                            <S.WrapperProfessor>
                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                                <S.DatePickerStyle
                                  value={moment.dtSchedule || 'Selecione um horário'}
                                  onChange={(newDate) => handleDateChange({ index, newDate: newDate as string })}
                                  inputFormat="DD/MM"
                                  disablePast
                                  dayOfWeekFormatter={formatDayOfWeek}
                                  shouldDisableDate={(date) => disabledDate(date as Dayjs, index + 1)}
                                  components={{
                                    OpenPickerIcon: UilCalender,
                                  }}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                        size="small"
                                        error={false}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: 'Data',
                                        }}
                                      />
                                    )
                                  }}
                                />
                              </LocalizationProvider>
                            </S.WrapperProfessor>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                              <S.SelectTeacher>
                                {moment.idProfessor && moment.idProfessor !== idUser && (
                                  <S.MenuOtherTeacher
                                    value={moment.idProfessor}
                                    sx={{
                                      minHeight: '40px',
                                    }}
                                  >
                                    <S.WrapperTeacher>
                                      {!moment.idProfessor && (
                                        <AvatarComponent
                                          size={'small'}
                                          label={moment.professor ? moment.professor?.txName : ''}
                                          photoUrl={moment.professor?.txImagePath}
                                        />
                                      )}

                                      {moment.idProfessor && (
                                        <AvatarComponent
                                          size={'small'}
                                          label={moment.professor ? moment.professor?.txName : ''}
                                          photoUrl={moment.professor?.txImagePath}
                                        />
                                      )}
                                      <S.NameProfessor>
                                        {!moment.idProfessor ? '' : moment.professor?.txName}
                                      </S.NameProfessor>
                                    </S.WrapperTeacher>
                                  </S.MenuOtherTeacher>
                                )}

                                {!moment.idProfessor && (
                                  <S.MenuTeacher
                                    value={moment.idProfessor}
                                    sx={{
                                      minHeight: '40px',
                                    }}
                                  >
                                    <S.WrapperTeacherSelect>
                                      {!moment.idProfessor && (
                                        <S.SwitchTeacher
                                          onChange={(e: SelectChangeEvent<HTMLSelectElement>) =>
                                            handleSelectFormChange(Number(idUser), moment.idMoment, index)
                                          }
                                        />
                                      )}

                                      {moment.idProfessor && (
                                        <AvatarComponent
                                          size={'small'}
                                          label={moment.professor ? moment.professor?.txName : ''}
                                          photoUrl={moment.professor?.txImagePath}
                                        />
                                      )}
                                      <S.NameProfessor>
                                        {!moment.idProfessor ? 'Selecionar aula' : moment.professor?.txName}
                                      </S.NameProfessor>
                                    </S.WrapperTeacherSelect>
                                  </S.MenuTeacher>
                                )}

                                {moment.idProfessor && moment.idProfessor === idUser && (
                                  <S.MenuTeacher
                                    disabled={['FINA', 'AVPE'].includes(moment.coMomentStatus)}
                                    value={moment.idProfessor}
                                    sx={{
                                      minHeight: '40px',
                                      maxWidth: '380px',
                                    }}
                                  >
                                    <S.WrapperTeacherSelect>
                                      {moment.idProfessor && (
                                        <S.SwitchTeacher
                                          color="primary"
                                          onChange={() => handleSelectFormChange(idUser, moment.idMoment, index)}
                                          checked={true}
                                          sx={{
                                            '& .MuiSwitch-track': {
                                              backgroundColor: '#f50057',
                                            },
                                            '& .MuiSwitch-switchBase': {
                                              color: '#f50057',
                                              '&.Mui-disabled': {
                                                color: '#e886a9',
                                              },
                                              '&.Mui-checked': {
                                                color: '#0095ff',
                                                '& + .MuiSwitch-track': {
                                                  backgroundColor: '#0095ff',
                                                },
                                              },
                                            },
                                            '& .MuiSwitch-primary': {
                                              '&.Mui-checked': {
                                                color: '#0095ff',
                                                '& + .MuiSwitch-track': {
                                                  backgroundColor: '#0095ff',
                                                },
                                              },
                                            },
                                          }}
                                        />
                                      )}

                                      {moment.idProfessor && (
                                        <AvatarComponent
                                          size={'small'}
                                          label={moment.professor ? moment.professor?.txName : ''}
                                          photoUrl={moment.professor?.txImagePath || photoUser || newAvatar}
                                        />
                                      )}
                                      <S.NameProfessorBold>
                                        {getFirstName(moment.professor?.txName || nameUser)}
                                      </S.NameProfessorBold>
                                      <S.ChipProfessor>você</S.ChipProfessor>
                                    </S.WrapperTeacherSelect>
                                  </S.MenuTeacher>
                                )}
                              </S.SelectTeacher>
                            </LocalizationProvider>
                          </S.WrapperMobile>
                          <S.DividerLine />
                        </S.WrapperContainer>
                      </>
                    )
                  })}
                </S.BoxOverflowMobile>
              </>
            )}
          </S.Content>

          {!isMobile && datesFormatted && (
            <TimeLineLessonComponent
              dates={datesFormatted}
              idTrack={idTrack}
              handleDateChange={(index, newDate) =>
                handleDateChange({
                  index,
                  newDate,
                })
              }
              open={open}
              disabledDate={(date: dayjs.Dayjs, order: number) => disabledDate(date, order)}
            />
          )}
        </S.ContentInfo>
      </S.Container>
    </>
  )
}

export default FormEM
