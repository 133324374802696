import React from 'react'

import { UilBook, UilCalender } from '@iconscout/react-unicons'
import { TextField, Tooltip } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { TextReductionComponent } from '~/components/Modals/AccordinModal/TextReduction'
import { useScheduModalContext } from '~/components/Modals/ScheduleModal/ScheduleContext'
import { TimeLineLessonComponent } from '~/components/Modals/TimelineLesson/TimelineLesson'

import { IDataClassInfosProps } from '~/pages/private/Trails/TrailDetailsProjectEM/interfaces'

import { formatDayOfWeek } from '~/validations/typeWeek'

import { Moment } from '../../ScheduleModal.interfaces'
import * as S from './FormEF.styles'

interface FormEMProps {
  dates: []
  title: string
  dataClassInfos: IDataClassInfosProps
  handleDateChange: () => void
  disabledDate: () => void
  datesFormatted: string
  validateMoment: () => void
  type: string
  setDates: () => unknown
}

const FormEM: React.FC<FormEMProps> = ({
  dates,
  title,
  dataClassInfos,
  handleDateChange,
  disabledDate,
  datesFormatted,
  validateMoment,
  type,
  setDates,
}) => {
  const { width } = useWindowDimensions()
  const { idTrack, open } = useScheduModalContext()
  const isMobile = Number(width) < 600

  const handleDateChangeSchedule = ({ index, newDate }: { index: number; newDate: string; type?: string }) => {
    const newDates = [...dates]

    if (index === 0) {
      for (let i = 1; i <= 3; i++) {
        if (!newDates[index + i].dtSchedule) {
          const currentDate = dayjs(newDate)
          const newDateIndex = currentDate.add(7 * i, 'days').format('YYYY-MM-DD')
          newDates[index + i].dtSchedule = newDateIndex
        }
      }
    }

    newDates[index].dtSchedule = newDate

    setDates(newDates)
  }

  return (
    <>
      <S.Container>
        <S.ContentInfo>
          <S.Content>
            {dates && !isMobile ? (
              <>
                <S.TitleCardProject>
                  <S.CardTitle>
                    <S.IconCircle>
                      <UilBook size={24} color="#0095FF" />
                    </S.IconCircle>
                    <S.TextTitleTrilhas variant="h3">
                      <TextReductionComponent text={title} maxLength={85} />
                    </S.TextTitleTrilhas>
                  </S.CardTitle>
                </S.TitleCardProject>
                <S.ContainerTrackClass>
                  <S.ClassSpanInfo>
                    {dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.txName}
                  </S.ClassSpanInfo>
                  <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.classShift?.txClassShift}</S.ClassSpanInfo>
                  <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.txGrade}</S.ClassSpanInfo>
                  <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.txName}</S.ClassSpanInfo>
                </S.ContainerTrackClass>
                <S.BoxOverflow className="styled-scroll-s">
                  {dates?.map((moment: Moment, index: number) => {
                    const lastIndex = dates.length
                    return (
                      <>
                        <S.WrapperDivider key={moment?.lessonTrackGroup?.nuOrder}>
                          <S.WrapperNumber>
                            <S.NumberBox>{index + 1}</S.NumberBox>
                          </S.WrapperNumber>
                          <S.WrapperText>
                            <Tooltip title={moment.title ? moment.title : ''}>
                              <S.TextClass variant="h1">
                                {(moment.title.length ?? 0) > 50 ? moment.title.substring(0, 50) + '...' : moment.title}
                              </S.TextClass>
                            </Tooltip>
                          </S.WrapperText>
                          <S.Wrapper>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                              <S.DatePickerStyle
                                value={moment.dtSchedule || 'Selecione um horário'}
                                onChange={
                                  ['edit'].includes(type)
                                    ? (newDate) => handleDateChange({ index, newDate: newDate as string })
                                    : (newDate) => handleDateChangeSchedule({ index, newDate: newDate as string })
                                }
                                inputFormat="DD/MM"
                                disablePast
                                disabled={validateMoment(moment)}
                                dayOfWeekFormatter={formatDayOfWeek}
                                shouldDisableDate={(date) => disabledDate(date as Dayjs, index + 1)}
                                components={{
                                  OpenPickerIcon: UilCalender,
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      size="small"
                                      error={false}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'Dia',
                                      }}
                                    />
                                  )
                                }}
                              />
                            </LocalizationProvider>
                          </S.Wrapper>
                        </S.WrapperDivider>
                        {index + 1 !== lastIndex && <S.DividerLine />}
                      </>
                    )
                  })}
                </S.BoxOverflow>
              </>
            ) : (
              <>
                <S.BoxOverflowMobile className="styled-scroll-xs">
                  <S.TitleCardProject>
                    <S.IconCircle>
                      <UilBook size={24} color="#0095FF" />
                    </S.IconCircle>
                    <S.TextTitleTrilhas variant="h3">
                      <TextReductionComponent text={title} maxLength={85} />
                    </S.TextTitleTrilhas>
                  </S.TitleCardProject>

                  <S.ContainerTrackClass>
                    <S.ClassSpanInfo>
                      {dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.txName}
                    </S.ClassSpanInfo>
                    <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.classShift?.txClassShift}</S.ClassSpanInfo>
                    <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.txGrade}</S.ClassSpanInfo>
                    <S.ClassSpanInfo>{dataClassInfos?.class?.items[0]?.txName}</S.ClassSpanInfo>
                  </S.ContainerTrackClass>
                  {dates?.map((moment: Moment, index: number) => {
                    return (
                      <>
                        <S.WrapperDivider key={moment?.lessonTrackGroup?.nuOrder}>
                          <S.WrapperNumber>
                            <S.NumberBox>{index + 1}</S.NumberBox>
                            <S.TextClass variant="h1">{moment.title}</S.TextClass>
                          </S.WrapperNumber>
                        </S.WrapperDivider>
                        <S.WrapperMobile>
                          <S.WrapperProfessor>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                              <S.DatePickerStyle
                                value={moment.dtSchedule || 'Selecione um horário'}
                                onChange={
                                  ['edit'].includes(type)
                                    ? (newDate) => handleDateChange({ index, newDate: newDate as string })
                                    : (newDate) => handleDateChangeSchedule({ index, newDate: newDate as string })
                                }
                                inputFormat="DD/MM/YYYY"
                                disablePast
                                dayOfWeekFormatter={formatDayOfWeek}
                                shouldDisableDate={(date) => disabledDate(date as Dayjs, index + 1)}
                                components={{
                                  OpenPickerIcon: UilCalender,
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      size="small"
                                      error={false}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'Dia',
                                      }}
                                    />
                                  )
                                }}
                              />
                            </LocalizationProvider>
                          </S.WrapperProfessor>
                        </S.WrapperMobile>
                        <S.DividerLine />
                      </>
                    )
                  })}
                </S.BoxOverflowMobile>
              </>
            )}
          </S.Content>

          {!isMobile && datesFormatted && (
            <TimeLineLessonComponent
              dates={datesFormatted}
              idTrack={idTrack}
              open={open}
              handleDateChange={(index, newDate) =>
                handleDateChange({
                  index,
                  newDate,
                })
              }
              disabledDate={(date: dayjs.Dayjs, order: number) => disabledDate(date, order)}
            />
          )}
        </S.ContentInfo>
      </S.Container>
    </>
  )
}

export default FormEM
