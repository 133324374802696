import { UilCheck, UilTrash, UilMultiply, UilArrowRight } from '@iconscout/react-unicons'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { useGetAssessmentCount } from '~/services/Assessment'

import { ButtonComponent } from '~/components'

import { ModalComponentBase } from '../ModalBase/Modal'
import { IModalGenericProps } from './ModalAction.interfaces'
import * as S from './ModalAction.styles'

export const ModalActionComponent = ({
  open,
  actionCloseModal,
  actionCofirm,
  title,
  icon,
  btnColor,
  returnBtnText,
  confirmCancelText,
  description,
  loading,
  infoWidth,
  isFullModal,
  isConfirm,
  isDesengage,
  isWarning,
  idClass,
  IdMoment,
}: IModalGenericProps) => {
  const { width } = useWindowDimensions()

  const { data: dataCount } = useGetAssessmentCount(idClass as number, IdMoment as number)

  return (
    <ModalComponentBase
      open={open}
      actionCloseModal={actionCloseModal}
      maxWidth={(width ?? 0) > (infoWidth ?? 0) ? '' : '350px'}
    >
      <S.Container>
        {isFullModal && (
          <>
            {isConfirm ? (
              <S.ContentIconFull color={isConfirm ? '#E8F6FF' : '#fff7f5'}>
                {icon ? icon : <UilTrash size={30} color="#0095FF" />}
              </S.ContentIconFull>
            ) : (
              <S.ContentIconFull color={isWarning ? '#fff7f5' : '#F4F7FE'}>
                {icon ? icon : <UilTrash size={30} color="#0095FF" />}
              </S.ContentIconFull>
            )}

            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>

            {isConfirm && (
              <S.ContentInfoBox>
                <S.ContentInfoTitle>Você realizou:</S.ContentInfoTitle>
                <S.ContentInfoWarn>
                  <S.TextInfoWarn
                    style={{
                      backgroundColor: (dataCount?.documentationsCount ?? 0) > 0 ? '#E1FFEB' : '#FFF7F5',
                      color: (dataCount?.documentationsCount ?? 0) > 0 ? '#29CC5F' : '#D44333',
                    }}
                  >
                    {dataCount?.documentationsCount ?? 0} documentações
                  </S.TextInfoWarn>
                  <S.TextInfoWarn
                    style={{
                      backgroundColor: (dataCount?.assessmentsCount ?? 0) > 0 ? '#E1FFEB' : '#FFF7F5',
                      color: (dataCount?.assessmentsCount ?? 0) > 0 ? '#29CC5F' : '#D44333',
                    }}
                  >
                    {dataCount?.assessmentsCount ?? 0} avaliações
                  </S.TextInfoWarn>
                </S.ContentInfoWarn>
              </S.ContentInfoBox>
            )}

            <S.ContentButtons>
              <ButtonComponent
                text={confirmCancelText}
                loading={loading}
                variant="solid"
                size="extra-large"
                color={btnColor}
                onClick={actionCofirm}
                fullWidth={(width ?? 0) < 700}
                iconStart={isConfirm ? <UilCheck size={25} color="#fff" /> : undefined}
                iconEnd={isDesengage ? <UilArrowRight size={20} color="#fff" /> : undefined}
              />
              <ButtonComponent
                text={returnBtnText}
                variant="text"
                size="extra-large"
                fontWeight="normal"
                onClick={actionCloseModal}
                fullWidth={(width ?? 0) < 700}
              />
            </S.ContentButtons>
          </>
        )}
        {!isFullModal && (
          <>
            <S.SmallView>
              <S.ContentIcon>{icon ? icon : <UilCheck size={25} color="#0095FF" />}</S.ContentIcon>
              <S.BtnClose onClick={actionCloseModal}>
                <UilMultiply size={16} color="#000" />
              </S.BtnClose>
            </S.SmallView>
            <S.Title>{title}</S.Title>
          </>
        )}
      </S.Container>
    </ModalComponentBase>
  )
}
