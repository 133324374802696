import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarComponent } from '~/components'

import { ICardStudentComponentProps } from './CardStudent.interfaces'
import * as S from './CardStudent.styles'

export const CardStudentComponent = ({ student, actionChangePicture }: ICardStudentComponentProps) => {
  const { width } = useWindowDimensions()
  const isMobile = width && width <= 600

  const lengthStudentConcat = isMobile ? 29 : 17

  const txNameStudentConcat =
    student?.txName?.length > lengthStudentConcat
      ? student?.txName?.slice(0, lengthStudentConcat).concat('...')
      : student?.txName

  return (
    <S.CardStudent onClick={actionChangePicture}>
      <S.CardWrapper>
        <AvatarComponent label={student?.txName} photoUrl={student?.txImagePath} size="mediumLarge" />

        <S.TextWrapper>
          <S.StudentName>{txNameStudentConcat}</S.StudentName>

          <S.ClassName>
            {student.classGrade} - {student.className}
          </S.ClassName>
        </S.TextWrapper>
      </S.CardWrapper>
    </S.CardStudent>
  )
}
