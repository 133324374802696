import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

interface IActiveProps {
  active: boolean
}

export const StepContainer = styledMUI(Box)(() => ({
  display: 'flex',
  padding: '24px',
  flexDirection: 'column',
  gap: '16px',
  alignSelf: 'stretch',
}))

export const StepHeader = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
}))

export const TitleLessonStep = styledMUI(Typography)(() => ({
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '120%',
  color: `${theme.colors.lowPure}`,
}))

export const ContentNumber = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.highPure};
  background-color: ${({ theme }) => theme.colors.midDark};
  z-index: 1;
`

export const StepNumber = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
`

export const StepContent = styledMUI(Box)(() => ({
  display: 'flex',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  backgroundColor: `${theme.colors.midLight}`,
}))

export const StepContentHeader = styledMUI(Box)(() => ({
  display: 'flex',
  height: '24px',
  alignItems: 'center',
  gap: '24px',
  alignSelf: 'stretch',
}))

export const BtnBack = styled.div`
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.colors.actionPure};
  color: ${({ theme }) => theme.colors.actionPure};
  cursor: pointer;
  transition: background 0.3s, color;
  &:hover {
    color: ${({ theme }) => theme.colors.actionDark};
    border: 1px solid ${({ theme }) => theme.colors.actionDark};
  }
`
export const TitleStep = styled.div`
  display: flex;
  gap: 8px;
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 400;
  line-height: 120%;
  color: ${theme.colors.quaternaryPure};
  flex-direction: row;
`

export const StepAbilityContent = styledMUI(Box)(() => ({
  display: 'flex',
  height: '32px',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
}))

export const SkillSpan = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '16px',
}))

export const StepDescriptionContent = styledMUI(Box)(() => ({
  display: 'flex',
  padding: '16px',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  backgroundColor: `${theme.colors.highPure}`,
}))

export const Description = styled.span`
  font-family: 'Inter';
  font-size: '16px';
  font-weight: 400;
  line-height: '150%';
  text-align: left;
`
export const BtnActionDescription = styled.button`
  margin-left: 2px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.actionPure};
  transition: all 0.5s;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.actionDark};
  }
`

export const DescriptionStep = styledMUI(Typography)(() => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '150%',
  color: `${theme.colors.lowPure}`,
}))

export const StepsContainer = styledMUI(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '4px',
  alignItems: 'center',
  gap: '4px',
  flex: '1 0 0',
  borderRadius: '50px',
  background: `${theme.colors.highPure}`,
  border: `1px solid ${theme.colors.midMedium}`,
  textOverflow: 'ellipsis',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '100%',
}))

export const BtnAction = styled.div<IActiveProps>`
  display: flex;
  height: 24px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 50px;
  transition: background 0.3s, color 0.3s;
  cursor: pointer;

  background: ${({ active, theme }) => (active ? theme.colors.midDark : theme.colors.highPure)};
  color: ${({ active, theme }) => (active ? theme.colors.highPure : theme.colors.midDark)};
  &:hover {
    background: ${({ theme }) => theme.colors.midDark};
    color: ${({ theme }) => theme.colors.highPure};
  }
`

export const StepsContent = styled.div<IActiveProps>`
  display: flex;
  height: 24px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;

  background: ${({ active, theme }) => (active ? theme.colors.midDark : theme.colors.highPure)};
  color: ${({ active, theme }) => (active ? theme.colors.highPure : theme.colors.midDark)};
  &:hover {
    background: ${({ theme }) => theme.colors.midDark};
    color: ${({ theme }) => theme.colors.highPure};
  }
`
