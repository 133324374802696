import React from 'react'
import ReactGA from 'react-ga4'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider } from 'styled-components'

import { AppBarProvider } from '~/_context/AppBar'
import { AuthProvider } from '~/_context/Auth'
import { OfflineProvider } from '~/_context/Offline'
import { SnackbarProvider } from '~/_context/Snackbar'

import { ReloadPrompt, SnackbarComponent } from '~/components'

import { Router } from '~/routes'

import { onLocalHost } from '~/validations/onLocalHost'

import { GlobalStyles } from '~/styles/global'
import theme from '~/styles/theme'

import packageJson from '../package.json'
import { ToastProvider } from './_context/Toast'

ReactGA.initialize('G-LZ1CN2YT2M')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

Sentry.init({
  dsn: 'https://85818d312da64516a2d19611bca03d85@sentry.lekto.com.br/2',
  release: 'plataforma-v2@' + packageJson.version,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 0.1,
  environment: import.meta.env.SENTRY_ENVIRONENT,
  enabled: onLocalHost(),
})

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <OfflineProvider>
          <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            <ReloadPrompt />

            <SnackbarProvider>
              <ToastProvider>
                <AppBarProvider>
                  <Router />
                </AppBarProvider>
              </ToastProvider>

              <SnackbarComponent />
            </SnackbarProvider>
          </QueryClientProvider>
        </OfflineProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default Sentry.withProfiler(App, { name: 'Plataform-Lekto' })
