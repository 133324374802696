import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { UilArrowRight, UilBookReader, UilCalender } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'

import {
  ActiveMomentCardComponent,
  AvatarComponent,
  AvatarGroupComponent,
  ButtonComponent,
  ChipComponent,
  SkeletonComponent,
  StatusTextComponent,
} from '~/components'
import ButtonStatus from '~/components/Forms/ButtonStatus/ButtonStatus'

import { getFormattedLabelWithDay } from '~/validations/formattLabelWithDay'

import { IHeaderLessonComponentProps, LessonComponent } from './HeaderLesson.interfaces'
import * as S from './HeaderLesson.styles'

export const HeaderLessonFullComponent = ({
  isMobile,
  loading,
  loadingChangeStatus,
  title,
  statusCode,
  statusText,
  dateHour = '',
  components,
  loadingStudents,
  avatas,
  classBefore,
  projectId,
  dtSchedule,
  actionStatus,
  actionManageStudents,
  isSameUser,
  professorImagePath,
  professorName,
  nuTrackStages,
  nuOrderTrackStages,
  nuLessonTrackGroups,
  nuOrderLessonTrackGroup,
  setShowTimeModal,
  isSameLesson,
  isSameProject,
  loadingStatus,
  isSameProfessor,
  isClassInStatus,
  isScrolling,
  idLesson,
  txTrackTitle,
  idMomentTrack,
  coStage,
}: IHeaderLessonComponentProps) => {
  const { idClass } = useParams()
  const isOfflineStorage = localStorage.getItem('@LEKTO:offline') || 'false'
  const isOffline = isOfflineStorage === 'true'
  const navigate = useNavigate()
  const firstMoment = classBefore?.filter((item) => item?.idMoment === Number(projectId))
  const momentBefore = classBefore?.filter((item) => item?.nuOrder === Number(firstMoment[0]?.nuOrder - 1))
  const isFilteredEmpty = momentBefore?.length === 0
  const newDateHour = new Date(dtSchedule as string)
  const showBtn = momentBefore[0]?.coMomentStatus === 'AVPE' || momentBefore[0]?.coMomentStatus === 'FINA'
  const today = new Date()
  const isToday = (someDate: any) => {
    const today = new Date()
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    )
  }

  function contarSecComponentes(components: LessonComponent[]) {
    let contador = 0
    components.forEach((item) => {
      if (item.coComponentType === 'SEC') {
        contador++
      }
    })

    return contador
  }

  const countSecComponents = contarSecComponentes(components ?? [])

  const [popoverContent, setPopoverContent] = useState<string[]>([])
  const [showPopover, setShowPopover] = useState<boolean>(false)

  const handleMouseEnter = (filteredComponents: LessonComponent[]) => {
    const content = filteredComponents.map((item: LessonComponent) => item.component?.txComponent ?? '')
    setPopoverContent(content)
    setShowPopover(true)
  }

  const handleMouseLeave = () => {
    setShowPopover(false)
  }

  const handleBncc = () => {
    if (isSameProject) {
      navigate(`/class/${idClass}/project-bncc-relations/${projectId}`)
    } else {
      navigate(`/class/${idClass}/lesson-bncc-relations/${projectId}/lesson/${idLesson}`)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const headerInfoGeneral = document.getElementById('header-info-general')
      const headerContainer = document.getElementById('header-container')

      if (headerInfoGeneral && headerContainer) {
        const headerInfoGeneralRect = headerInfoGeneral.getBoundingClientRect()
        if (headerInfoGeneralRect.top <= 0) {
          headerContainer.style.position = 'fixed'
          headerContainer.style.top = '0'
          headerContainer.style.width = '100%'
          headerInfoGeneral.style.visibility = 'hidden'
        } else {
          headerContainer.style.position = 'relative'
          headerInfoGeneral.style.visibility = 'visible'
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  return (
    <S.HeaderContainer component="header">
      {!isScrolling && (
        <>
          <S.HeaderInfoGeneral isMobile={Boolean(isMobile)}>
            <S.HeaderInfo>
              <S.textTrack>Trilha</S.textTrack>
              <ActiveMomentCardComponent
                isOffline={isOfflineStorage}
                lessons={nuLessonTrackGroups}
                thisLesson={Number(nuOrderLessonTrackGroup)}
                color={statusCode}
                txTrackTitle={txTrackTitle}
                idMomentTrack={idMomentTrack}
                idClass={idClass}
                coStage={coStage}
              />
            </S.HeaderInfo>
            <S.HeaderInfo>
              <StatusTextComponent statusCode={statusCode} statusText={statusText} />
              <ChipComponent
                type={statusCode === 'CANC' ? 'cancel' : statusCode}
                icon={<UilCalender size={16} />}
                label={getFormattedLabelWithDay(dtSchedule as string)}
              />
            </S.HeaderInfo>
          </S.HeaderInfoGeneral>

          <S.Title variant="h1">{loading ? <SkeletonComponent width={400} /> : title}</S.Title>
        </>
      )}

      <S.WrapperStudents>
        {loadingStudents ? (
          <SkeletonComponent variant="circular" width={40} height={40} />
        ) : (
          <AvatarGroupComponent images={avatas} max={4} size="small" />
        )}

        <Tooltip
          title={isOffline ? 'Para acessar o gerenciamento de alunos você precisa sair do modo offline' : ''}
          placement="top"
        >
          <span>
            {!loading && (
              <>
                {isSameProject && (
                  <ButtonComponent
                    variant="outline"
                    color={isOffline || statusCode === 'FINA' || !isSameUser ? 'disabled' : undefined}
                    size="xsmall"
                    text="Visualizar Grupos"
                    onClick={actionManageStudents}
                    disabled={isOffline || !isSameUser || statusCode === 'FINA'}
                  />
                )}

                {isSameLesson && (
                  <>
                    <ButtonComponent
                      variant="outline"
                      color={
                        isOffline || statusCode === 'FINA' || statusCode === 'AGEN' || !isSameProfessor
                          ? 'disabled'
                          : undefined
                      }
                      size="xsmall"
                      text="Visualizar Grupos"
                      onClick={actionManageStudents}
                      disabled={isOffline || statusCode === 'FINA' || statusCode === 'AGEN' || !isSameProfessor}
                    />
                  </>
                )}
              </>
            )}
          </span>
        </Tooltip>

        {!(isScrolling && isMobile) && (
          <S.HeaderInfo>
            {isSameProject && (
              <>
                {components
                  ?.filter((item: LessonComponent) => item.coComponentType === 'PRI')
                  .map((item: LessonComponent, index: number) => (
                    <S.ComponentContentPopover
                      key={index}
                      onMouseEnter={() => handleMouseEnter(components)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <ChipComponent
                        key={index}
                        type="normal"
                        label={item.component?.txComponent}
                        count={countSecComponents}
                        isSametag={true}
                      />
                      {showPopover && (
                        <S.ContainerPopover>
                          <S.TitleTooltip>Componentes dessa aula:</S.TitleTooltip>
                          <S.ContentTooltip>
                            {popoverContent?.map((content, index) => (
                              <>
                                <S.TextTooltip
                                  key={index}
                                  className={index === popoverContent?.length - 1 ? 'last-item' : ''}
                                >
                                  {index === 0 ? '' : index === popoverContent?.length - 1 ? ' e ' : ', '}
                                  {content}
                                </S.TextTooltip>
                              </>
                            ))}
                          </S.ContentTooltip>
                          {isMobile ? (
                            <Tooltip title="Acessar Integração com o Currículo">
                              <S.ButtonIcon onClick={() => handleBncc()}>
                                <UilBookReader size={16} color="#0095ff" />
                              </S.ButtonIcon>
                            </Tooltip>
                          ) : (
                            <ButtonComponent
                              text="Acessar Integração com o Currículo"
                              variant="outline"
                              size="small"
                              iconEnd={<UilArrowRight size="18" />}
                              fontWeight="normal"
                              onClick={() => handleBncc()}
                            />
                          )}
                        </S.ContainerPopover>
                      )}
                    </S.ComponentContentPopover>
                  ))}
              </>
            )}
            {isSameLesson && (
              <>
                {components?.map((component) => (
                  <S.ComponentContentPopover
                    key={component?.coComponent}
                    onMouseEnter={() => handleMouseEnter(components)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <ChipComponent
                      key={component?.coComponent}
                      type="normal"
                      label={
                        (component?.component?.txComponent.length ?? 0) > 25
                          ? component?.component?.txComponent.substring(0, 25) + '...'
                          : component?.component?.txComponent
                      }
                      count={countSecComponents}
                      isSametag={true}
                    />
                    {showPopover && (
                      <S.ContainerPopover>
                        <S.TitleTooltip>Componentes dessa aula:</S.TitleTooltip>

                        <S.ContentTooltip>
                          {popoverContent?.map((content, index) => (
                            <>
                              <S.TextTooltip
                                key={index}
                                className={index === popoverContent?.length - 1 ? 'last-item' : ''}
                              >
                                {index === 0 ? '' : index === popoverContent?.length - 1 ? ' e ' : ', '}
                                {content}
                              </S.TextTooltip>
                            </>
                          ))}
                        </S.ContentTooltip>
                        {isMobile ? (
                          <Tooltip title="Acessar Integração com o Currículo">
                            <S.ButtonIcon onClick={() => handleBncc()}>
                              <UilBookReader size={16} color="#0095ff" />
                            </S.ButtonIcon>
                          </Tooltip>
                        ) : (
                          <ButtonComponent
                            text="Acessar Integração com o Currículo"
                            variant="outline"
                            size="small"
                            iconEnd={<UilArrowRight size="18" />}
                            fontWeight="normal"
                            onClick={() => handleBncc()}
                          />
                        )}
                      </S.ContainerPopover>
                    )}
                  </S.ComponentContentPopover>
                ))}
              </>
            )}
            {professorName && (
              <S.ConteinerProfessor>
                <AvatarComponent
                  key={professorName}
                  label={professorName}
                  photoUrl={professorImagePath}
                  size="xsmall"
                />
                {isMobile ? (
                  <>
                    <Tooltip title={professorName}>
                      <S.TextInfoLabel>{truncateText(professorName, 8)}</S.TextInfoLabel>
                    </Tooltip>
                    <S.TextInfoLabel>{isSameUser && <span>você</span>}</S.TextInfoLabel>
                  </>
                ) : (
                  <S.TextInfoLabel>
                    {truncateText(professorName, 12)} {isSameUser && <span>- você</span>}
                  </S.TextInfoLabel>
                )}
              </S.ConteinerProfessor>
            )}
          </S.HeaderInfo>
        )}
      </S.WrapperStudents>

      {!isScrolling && (
        <>
          {isSameProject && (
            <>
              {!loading && (
                <ButtonStatus
                  size="large"
                  variant="solid"
                  onClick={() => actionStatus({})}
                  loadingStatus={loading || false}
                  loading={loadingChangeStatus || false}
                  isToday={isToday}
                  newDateHour={newDateHour}
                  nuOrder={nuOrderTrackStages}
                  today={today}
                  isMobile={isMobile}
                  statusCode={statusCode || ''}
                  isFilteredEmpty={isFilteredEmpty}
                  showBtn={showBtn}
                  momentBefore={momentBefore}
                  isSameProfessor={isSameUser || false}
                  isClassInStatus={isClassInStatus}
                  isOffline={isOffline || false}
                  setShowTimeModal={(value) => setShowTimeModal(value)}
                  dateHour={dateHour}
                />
              )}
            </>
          )}

          {isSameLesson && (
            <ButtonStatus
              size="large"
              variant="solid"
              onClick={() => actionStatus({})}
              loadingStatus={loadingStatus || false}
              loading={loading || false}
              isToday={isToday}
              isMobile={isMobile}
              newDateHour={newDateHour}
              nuOrder={nuOrderTrackStages}
              today={today}
              statusCode={statusCode || ''}
              isFilteredEmpty={isFilteredEmpty}
              showBtn={showBtn}
              momentBefore={momentBefore}
              isSameProfessor={isSameProfessor || false}
              isClassInStatus={isClassInStatus}
              isOffline={isOffline || false}
              setShowTimeModal={setShowTimeModal}
              dateHour={dateHour}
            />
          )}

          {isScrolling && isMobile && (
            <S.HeaderInfoGeneral isMobile={Boolean(isMobile)}>
              <S.HeaderInfo>
                <S.textTrack>Trilha</S.textTrack>
                <ActiveMomentCardComponent
                  isOffline={isOfflineStorage}
                  lessons={nuLessonTrackGroups}
                  thisLesson={Number(nuOrderLessonTrackGroup)}
                  color={statusCode}
                  txTrackTitle={txTrackTitle}
                  idMomentTrack={idMomentTrack}
                  idClass={idClass}
                  coStage={coStage}
                />
              </S.HeaderInfo>
              <S.HeaderInfo>
                <StatusTextComponent statusCode={statusCode} statusText={statusText} />
                <ChipComponent
                  type={statusCode}
                  icon={<UilCalender size={16} />}
                  label={getFormattedLabelWithDay(dtSchedule as string)}
                />
              </S.HeaderInfo>
            </S.HeaderInfoGeneral>
          )}
        </>
      )}
    </S.HeaderContainer>
  )
}
