import dayjs from 'dayjs'

import { ITrailStatusBarProps } from '~/components/Bars/TrailStatusBar/TrailStatusBar.interfaces'

import isMobile from '~/validations/isMobile'
import { statusColor } from '~/validations/statusColorsEM'
import { statusIcon } from '~/validations/statusIcon'

import theme from '~/styles/theme'

import * as S from './TrailStatusBar.styles'

export const TrailStatusBar = ({ moments, momentActive }: ITrailStatusBarProps) => {
  const finishedStatus = ['CANC', 'FINA']
  let isPreviousMomentFinished = false
  return (
    <S.StatusBarContainer>
      {moments &&
        moments.map((moment, index) => {
          if (
            finishedStatus.includes(moment.coMomentStatus) ||
            (dayjs(moment.dtSchedule).isBefore(dayjs(), 'day') && momentActive !== moment.nuTrackOrder)
          ) {
            isPreviousMomentFinished = true
            return (
              <S.StatusBarTooltip
                title={`${moment.txMomentStatus} | ${dayjs(moment.dtSchedule).format('DD/MM')}`}
                key={moment.coMomentStatus + index}
              >
                <S.StatusInfo isActive={false}>
                  <span>{statusIcon(moment.coMomentStatus, 16)}</span>
                </S.StatusInfo>
              </S.StatusBarTooltip>
            )
          } else if (momentActive === moment.nuTrackOrder || isPreviousMomentFinished) {
            isPreviousMomentFinished = false
            return (
              <S.StatusInfo
                key={moment.nuTrackOrder + index}
                isActive={true}
                statusColor={statusColor(moment.coMomentStatus).indexColor}
              >
                {isMobile() ? (
                  <S.StatusBarTooltip title={`${moment.txMomentStatus} | ${dayjs(moment.dtSchedule).format('DD/MM')}`}>
                    <span>{statusIcon(moment.coMomentStatus, 16, theme.colors.highPure)}</span>
                  </S.StatusBarTooltip>
                ) : (
                  <>
                    <strong>Aula {moment.nuTrackOrder}</strong>
                    <span>{moment.txMomentStatus}</span>
                    <span>{dayjs(moment.dtSchedule).format('DD/MM')}</span>
                  </>
                )}
              </S.StatusInfo>
            )
          } else {
            return (
              <S.StatusBarTooltip
                title={`${moment.txMomentStatus} | ${dayjs(moment.dtSchedule).format('DD/MM')}`}
                key={moment.dtSchedule + index}
              >
                <S.StatusInfo isActive={false}>
                  <span>{moment.nuTrackOrder}</span>
                </S.StatusInfo>
              </S.StatusBarTooltip>
            )
          }
        })}
    </S.StatusBarContainer>
  )
}
