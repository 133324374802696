import { Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'
interface IActiveProps {
  active: boolean
  isNull?: boolean
}

export const Container = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  flex: '1 0 0',
}))

export const StepsContainer = styledMUI(Box)(() => ({
  display: 'flex',
  padding: '4px',
  alignItems: 'center',
  gap: '4px',
  flex: '1 0 0',
  borderRadius: '50px',
  background: `${theme.colors.highPure}`,
  border: `1px solid ${theme.colors.midMedium}`,
  textOverflow: 'ellipsis',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '100%',
}))

export const StepsContent = styled.div<IActiveProps>`
  display: flex;
  height: 24px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 50px;
  transition: background 0.3s, color 0.3s;

  background: ${({ active, theme }) => (active ? theme.colors.midDark : theme.colors.highPure)};
  color: ${({ active, theme }) => (active ? theme.colors.highPure : theme.colors.midDark)};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.midDark};
    color: ${({ theme }) => theme.colors.highPure};
  }
`

export const BtnAction = styled.div<IActiveProps>`
  display: flex;
  height: 24px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 50px;
  transition: background 0.3s, color 0.3s;
  cursor: pointer;

  background: ${({ active, theme }) => (active ? theme.colors.midDark : theme.colors.highPure)};
  color: ${({ active, theme }) => (active ? theme.colors.highPure : theme.colors.midDark)};
  &:hover {
    background: ${({ theme }) => theme.colors.midDark};
    color: ${({ theme }) => theme.colors.highPure};
  }
`
