import { useState } from 'react'

import { UilCloudDownload, UilDownloadAlt, UilFile, UilMultiply } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { ButtonComponent, ModalComponent } from '~/components'

import { LessonFile } from '~/pages/private/Fund1/Lesson/interfaces'

import * as S from './ModalDownloadList.styles'

type IModalDownloadListProps = {
  files: LessonFile[]
}

export const ModalDownloadList = ({ files }: IModalDownloadListProps) => {
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  const [showDownloadListModal, setShowDownloadListModal] = useState<boolean>(false)
  const [downloadedFiles, setDownloadedFiles] = useState<Set<string>>(new Set())

  const handleCloseModal = () => {
    setShowDownloadListModal(false)
  }
  const handleOpenFile = (url: string, fileName: string) => () => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))

        const link = document.createElement('a')
        link.href = url
        link.download = fileName

        document.body.appendChild(link)

        link.click()

        link.parentNode?.removeChild(link)
      })

    setTimeout(() => {
      const updatedDownloadedFiles = new Set(downloadedFiles)
      updatedDownloadedFiles.add(fileName)
      setDownloadedFiles(updatedDownloadedFiles)
    }, 800)
  }

  if (!files || files.length === 0) return null

  return (
    <>
      {isMobile ? (
        <Tooltip title="Baixar Materiais">
          <S.ButtonIcon onClick={() => setShowDownloadListModal(true)}>
            <UilDownloadAlt size={16} color="#0095ff" />
          </S.ButtonIcon>
        </Tooltip>
      ) : (
        <ButtonComponent
          onClick={() => setShowDownloadListModal(true)}
          text="Baixar Materiais"
          variant="outline"
          size="small"
          iconStart={<UilDownloadAlt size="18" />}
          fontWeight="normal"
        />
      )}

      {showDownloadListModal && (
        <ModalComponent open={showDownloadListModal} actionCloseModal={handleCloseModal}>
          <S.Container>
            <S.WrapperTitle>
              <S.Title variant="h1">Baixar Materiais</S.Title>

              <S.ButtonClose onClick={handleCloseModal}>{<UilMultiply size={15} color="#000" />}</S.ButtonClose>
            </S.WrapperTitle>

            <S.Description>Baixe os arquivos para utilizá-los durante a aula.</S.Description>

            <S.Content>
              {files.map((file, index) => (
                <S.DownloadButton
                  key={index}
                  onClick={handleOpenFile(file.mediaInformation.txAbsoluteUrl, file.mediaInformation.txName)}
                  variant={downloadedFiles.has(file.mediaInformation.txName) ? 'disabled' : 'default'}
                >
                  <div>
                    <UilFile
                      size={24}
                      color={downloadedFiles.has(file.mediaInformation.txName) ? '#A3A3A3' : '#0095ff'}
                    />
                    <span> {file.mediaInformation.txName}</span>
                  </div>
                  <div>
                    {downloadedFiles.has(file.mediaInformation.txName) && <S.DownloadedText>Baixado</S.DownloadedText>}
                    <S.ContainerIcon
                      variant={downloadedFiles.has(file.mediaInformation.txName) ? 'disabled' : 'default'}
                    >
                      <UilCloudDownload
                        size={16}
                        color={downloadedFiles.has(file.mediaInformation.txName) ? '#A3A3A3' : '#0095ff'}
                      />
                    </S.ContainerIcon>
                  </div>
                </S.DownloadButton>
              ))}
            </S.Content>
          </S.Container>
        </ModalComponent>
      )}
    </>
  )
}
