import { Box, Card, IconButton, Tooltip, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const CardContainer = styledMUI(Card)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F4F7FE',
  padding: '16px',
  gap: '16px',
  borderRadius: '8px',
  boxShadow: 'none',
}))

export const WrapperBetween = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const ConteinerProfessor = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  border: `1px solid ${theme.colors.lowLight}`,
  borderRadius: '4px',
  padding: '4px',
  height: '24px',
}))

export const NameProfessor = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
`
export const WrapperSchedule = styledMUI(Box)(() => ({
  display: 'flex',
}))

export const WrapperTitle = styledMUI(Box)(() => ({
  maxHeight: '48px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const WrapperChip = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '590px',
  overflowY: 'auto',
  '@media (max-width: 600px)': {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

export const ContainerDivider = styled(Box)(() => ({
  maxWidth: '240px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  justifyContent: 'space-evenly',
  color: theme.colors.midDark,
  gap: 4,
  fontWeight: 400,
}))

export const ComponentContentPopover = styledMUI(Box)(() => ({
  display: 'flex',
}))

export const ContainerPopover = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: '8px',
  width: '334px',
  marginTop: '26px',
  borderRadius: '8px',
  padding: '16px',
  backgroundColor: `${theme.colors.highPure}`,
  boxShadow: `0px 4px 4px ${theme.shadows['shadow-level-4']}`,
  transition: '0.8',
}))

export const TooltipContainer = styledMUI(Box)({
  position: 'relative',
})

export const TooltipContainerHover = styledMUI(Box)({
  '&:hover $customPaper': {
    position: 'relative',
  },
})

export const TitleTooltip = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.lowLight};
`
export const ContentTooltip = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
export const TextTooltip = styled.span`
  flex-direction: row-reverse;
  gap: 8px;
  display: flex;
  font-size: 14px;
  &.last-item {
    margin-left: 5px;
  }
`

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))

export const ContentOrder = styledMUI(Box)(() => ({
  display: 'flex',
  width: '24px',
  height: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '64px',
  backgroundColor: '#fff',
  color: '#666',
}))

export const TextOrder = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: '#666',
}))

export const Title = styledMUI(Typography)(() => ({
  display: 'flex',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  color: '#000',
}))

export const ContainerOrderSchedule = styledMUI(Box)(() => ({
  width: '98.89%',
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
}))

export const ContentOrderSchedule = styledMUI(Box)(() => ({
  width: '17.69px',
  height: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '64px',
  backgroundColor: '#BDC3CF',
}))

export const TextOrderSchedule = styledMUI(Typography)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: 'normal',
  color: '#fff',
}))

export const ButtonIcon = styledMUI(IconButton)<{ isMobile?: boolean }>(({ isMobile }) => ({
  display: 'flex',
  height: '24px',
  padding: isMobile ? '4px 4px' : '4px 8px',
  alignItems: 'center',
  gap: '8px',
  flexShrink: 0,
  justifyContent: 'center',
  borderRadius: '50px',
  border: `1px solid ${theme.colors.actionPure}`,
  color: theme.colors.actionPure,
  backgroundColor: 'transparent',
  transition: 'all 0.5s',

  '&:hover': {
    borderColor: theme.colors.actionDark,
    backgroundColor: 'transparent',
    boxShadow: theme.shadows['shadow-level-3'],
    color: theme.colors.actionDark,
  },
}))

export const TextButton = styled.span`
  font-size: 14px;
  font-weight: 400;
`

export const Content = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '16px',

  '@media (max-width: 768px)': {
    display: 'grid',
  },
}))

export const ContentInfos = styledMUI(Box)(() => ({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  '@media (max-width: 768px)': {
    width: '100%',
  },
}))

export const TextTeacher = styledMUI(Typography)(() => ({
  display: 'flex',
  gap: '8px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  // color: '#000',
}))

export const Description = styledMUI(Box)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const ContentSchedules = styledMUI(Box)(() => ({
  maxHeight: '196px',
  height: 'max-content',
  width: '230px',
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  gap: '8px',
  backgroundColor: '#FFF',
  borderRadius: '8px',
}))

export const ContentChipsSchedules = styledMUI(Box)(() => ({
  height: '116px',
  width: '214px',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  overflowY: 'auto',
}))

export const TextSchedules = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: '#000',
}))
