import { gql } from 'graphql-request'

export const GET_CLASSES_TODAY = gql`
  query getClassesToday($dateStart: DateTime!, $dateEnd: DateTime!, $showScheduledClasses: Boolean!) {
    scheduledMomentByProfessor(
      dtScheduleStart: $dateStart
      dtScheduleEnd: $dateEnd
      showScheduledClasses: $showScheduledClasses
    ) {
      items {
        idMoment
        coMomentStatus
        txMomentStatus
        nuOrder
        dtSchedule
        nuTrackStages
        txTitle
        txTrackTitle
        idMomentTrack

        class {
          txName
          idClass

          schoolGrade {
            grade {
              txGrade
              coStage
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
