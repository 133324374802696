import { UilArrowRight } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'

import { ICardNavigationComponentProps } from './CardNavigation.interfaces'
import * as S from './CardNavigation.styles'

export const CardNavigationComponent = ({
  icon,
  label,
  pathNavigation,
  disabled,
  tooltip,
}: ICardNavigationComponentProps) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement={'top'}>
        <span>
          <S.ContainerLink to={!disabled ? pathNavigation : ''}>
            <S.Container disabled={disabled}>
              <S.LabelWrapper>
                <S.Icon disabled={disabled}>{icon}</S.Icon>
                <S.TextLabel disabled={disabled} variant="caption">
                  {label}
                </S.TextLabel>
              </S.LabelWrapper>

              <S.ContentArrow disabled={disabled}>
                <UilArrowRight size={20} color={disabled ? '#E0E0E0' : '#0095FF'} />
              </S.ContentArrow>
            </S.Container>
          </S.ContainerLink>
        </span>
      </Tooltip>
    )
  }
  return (
    <S.ContainerLink to={!disabled ? pathNavigation : ''}>
      <S.Container disabled={disabled}>
        <S.LabelWrapper>
          <S.Icon disabled={disabled}>{icon}</S.Icon>
          <S.TextLabel disabled={disabled} variant="caption">
            {label}
          </S.TextLabel>
        </S.LabelWrapper>

        <S.ContentArrow disabled={disabled}>
          <UilArrowRight size={20} color={disabled ? '#E0E0E0' : '#0095FF'} />
        </S.ContentArrow>
      </S.Container>
    </S.ContainerLink>
  )
}
