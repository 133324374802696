import { useNavigate, useParams } from 'react-router-dom'

import { Box } from '@mui/material'
import { gaEvents } from '~/events'

import { AppBarComponent, ContainerComponent, ValidateDataSessionComponent } from '~/components'

import { CardTrailComponent, HeaderComponent } from '~/containers/private/Trails/TrailsAvailable'

import { useListTrailsAvailablePageContext } from './context'
import * as S from './styles'

export const ListTrailsAvailableClassView = () => {
  const navigate = useNavigate()
  const { classId } = useParams()

  const { isLoadingClassInfos, dataClassInfos, isLoadingTrailsAvailable, listOfAvailableTrailsResponse } =
    useListTrailsAvailablePageContext()

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderComponent
          loading={isLoadingClassInfos}
          path={`/class-details/${classId}`}
          coStage={dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.txName}
          schoolGrade={dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.txGrade}
          coGrade={dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.coGrade}
          classShift={dataClassInfos?.class?.items[0]?.classShift?.txClassShift}
          textGrade={`${dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.txGrade}`}
          textName={dataClassInfos?.class?.items[0]?.txName}
        />

        <>
          {!isLoadingTrailsAvailable && !listOfAvailableTrailsResponse?.length ? (
            <S.ContentEmoji>
              <ValidateDataSessionComponent
                screen="home"
                title="Você não tem nenhuma trilha disponível."
                description="Entre em contato com a coordenação da sua escola ou com o suporte Lekto para mais informações."
              />
            </S.ContentEmoji>
          ) : (
            <S.ContentList component="ul">
              <Box>
                <S.Description>Explore as trilhas que você pode escolher para esta turma</S.Description>
              </Box>
              {isLoadingTrailsAvailable ? (
                <>
                  {Object.keys(new Array(8).fill(null)).map((_, index: number) => (
                    <CardTrailComponent loading={isLoadingTrailsAvailable} key={index} />
                  ))}
                </>
              ) : (
                <>
                  {listOfAvailableTrailsResponse?.map((trail) => (
                    <>
                      <CardTrailComponent
                        key={trail.idTrack}
                        title={trail.txTitle}
                        subjects={trail.component}
                        actionShowDetails={() => {
                          navigate(`/trail-details/${trail.idTrack}/class/${classId}`)
                          gaEvents.eventSeeMoreTrailButton()
                        }}
                      />
                    </>
                  ))}
                </>
              )}
            </S.ContentList>
          )}
        </>
      </ContainerComponent>

      {/*     <ModalInfoComponent
        open={openModalInfo}
        text={'Como selecionar uma trilha?'}
        actionCloseModal={handleCloseModalInfo}
      /> */}
    </>
  )
}
