import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UilArrowRight, UilBookReader, UilCalender, UilExclamationCircle } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import {
  AvatarGroupComponent,
  BackNavigationComponent,
  ButtonComponent,
  ChipComponent,
  ScheduleModalComponent,
  SkeletonComponent,
} from '~/components'
import TagClass from '~/components/Cards/TagClass/TagClass'
import InactiveTag from '~/components/ui/InactiveTag/InactiveTag'

import { mapCoStage } from '~/validations/mapCoStage'
import { validateType } from '~/validations/setTypeModal'
import { truncateText } from '~/validations/truncateText'

import { IComponentProps } from '~/interfaces/Component'

import { ITrailsHeaderProps, Moment } from './Header.interfaces'
import * as S from './Header.styles'

export const TrailsHeaderComponent = ({
  actionOpenModalBNCC,
  txTitle,
  component,
  professor,
  dataClassInfos,
  moment,
  loading,
  idTrack,
  isModalOpen,
  actionOpenModalSchedule,
  actionCloseModalSchedule,
  txSchoolGradeName,
  coStage,
  isDisabledClasses,
  txClassShift,
  txGrade,
  isSameUser,
}: ITrailsHeaderProps) => {
  const { width } = useWindowDimensions()
  const isMobile = Number(width) < 600
  const { classId } = useParams()

  const userInfo = JSON.parse(localStorage?.getItem('@LEKTO:user') as string)

  const [showPopover, setShowPopover] = useState<boolean>(false)
  const [popoverContent, setPopoverContent] = useState<string>()
  const [countTeacher, setCountTeacher] = useState(0)

  const handleMouseEnter = (filteredComponents: string) => {
    setPopoverContent(filteredComponents)
    setShowPopover(true)
  }

  const handleMouseLeave = () => {
    setShowPopover(false)
  }

  function validateProfessors(moments: Moment[], loggedInUserId: number) {
    let containsEmptyProfessor = false
    const allOccupiedByLoggedInUser = true
    const coStageCheck = coStage === 'F1' || coStage === 'F2'

    if (moments) {
      for (const moment of moments) {
        if (!moment.professor) {
          containsEmptyProfessor = true
        } else if (moment.professor.idUser === loggedInUserId) {
          return true
        }
      }

      if (coStageCheck && professor) {
        for (const professorData of professor) {
          if (professorData.idUser === loggedInUserId) {
            return true
          }
          if (professorData.idUser !== loggedInUserId) {
            return false
          }
        }
      }

      if (professor) {
        for (const professorData of professor) {
          if (professorData.idUser === loggedInUserId) {
            return true
          } else if (professorData.idUser !== loggedInUserId && !containsEmptyProfessor && !coStageCheck) {
            return false
          } else if (professorData.idUser !== loggedInUserId && containsEmptyProfessor && !coStageCheck) {
            return true
          }
        }
      }
    }

    if (!allOccupiedByLoggedInUser && containsEmptyProfessor) {
      return true
    }

    if (containsEmptyProfessor) {
      return true
    }

    if (allOccupiedByLoggedInUser) {
      return true
    }

    return false
  }

  useEffect(() => {
    const teacherPresent = professor?.some((teacher) => teacher.txName === professor[0]?.txName)
    const otherTeachersCount = professor?.filter((teacher) => teacher.txName !== professor[0]?.txName)?.length || 0

    setCountTeacher(otherTeachersCount)
    if (teacherPresent && otherTeachersCount > 0) {
      setCountTeacher(otherTeachersCount)
    } else {
      setCountTeacher(otherTeachersCount)
    }
  }, [professor])

  const previousParent = localStorage.getItem('@LEKTO:Navigation')
  const allWithProfessor = validateProfessors(moment ? moment : [], userInfo?.id_user)
  return (
    <S.ContentHeader>
      <S.HeaderFlex>
        <S.Return>
          <BackNavigationComponent
            name={'Todas as Trilhas'}
            path={previousParent ? previousParent : `/class-details/${classId}`}
          />
        </S.Return>

        <S.HeaderBtns>
          <ButtonComponent
            variant="outline"
            size="small"
            iconStart={<UilCalender size={16} />}
            text="Alterar Datas"
            fontWeight="normal"
            color={!allWithProfessor ? 'disabled' : ''}
            disabled={!allWithProfessor}
            onClick={actionOpenModalSchedule}
          />
        </S.HeaderBtns>
      </S.HeaderFlex>
      <S.HeaderTitleDiv>
        {loading ? (
          <>
            <SkeletonComponent variant="rounded" height={50} width={'80%'} />
          </>
        ) : (
          <>
            <S.HeaderTitle>{txTitle}</S.HeaderTitle>
          </>
        )}
      </S.HeaderTitleDiv>

      <S.HeaderInfo>
        <S.TrackDescription>
          {loading ? (
            <>
              <SkeletonComponent variant="rounded" height={50} width={'80%'} />
            </>
          ) : (
            <TagClass
              variant={'default'}
              texts={[
                txClassShift as string,
                mapCoStage(coStage!),
                txGrade as string,
                truncateText(txSchoolGradeName as string, 10),
              ]}
            />
          )}

          <S.Wrapper>
            {loading ? (
              <>
                {Object.keys(new Array(3).fill(null)).map((_, index: number) => (
                  <SkeletonComponent key={index} variant="rounded" height={26} width={70} />
                ))}
              </>
            ) : (
              <>
                {component?.map((component: IComponentProps) => (
                  <S.ComponentContentPopover
                    key={component?.coComponentType}
                    onMouseEnter={() => handleMouseEnter(component.txComponent)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <ChipComponent
                      key={component?.coComponentType}
                      type="normal"
                      label={
                        (component?.txComponent.length ?? 0) > 25
                          ? component?.txComponent.substring(0, 25) + '...'
                          : component?.txComponent
                      }
                      isSametag={true}
                    />
                    {isDisabledClasses === false && <InactiveTag />}

                    {showPopover && (
                      <S.ContainerPopover>
                        <S.TitleTooltip>Componente dessa aula:</S.TitleTooltip>

                        <S.ContentTooltip>
                          <>
                            <S.TextTooltip>{popoverContent}</S.TextTooltip>
                          </>
                        </S.ContentTooltip>
                        {isMobile ? (
                          <Tooltip title="Acessar Integração com o Currículo">
                            <S.ButtonIcon onClick={actionOpenModalBNCC}>
                              <UilBookReader size={16} color="#0095ff" />
                            </S.ButtonIcon>
                          </Tooltip>
                        ) : (
                          <ButtonComponent
                            text="Acessar Integração com o Currículo"
                            variant="outline"
                            size="small"
                            iconEnd={<UilArrowRight size="18" />}
                            fontWeight="normal"
                            onClick={actionOpenModalBNCC}
                          />
                        )}
                      </S.ContainerPopover>
                    )}
                  </S.ComponentContentPopover>
                ))}
              </>
            )}
          </S.Wrapper>

          <S.Wrapper>
            {loading ? (
              <SkeletonComponent variant="circular" height={32} width={32} />
            ) : (
              <>
                {professor?.[0]?.txName && (
                  <Tooltip
                    title={
                      <ul>
                        {professor?.map((item, index) => (
                          <li key={index}>{item.txName}</li>
                        ))}
                      </ul>
                    }
                    placement="top"
                  >
                    <S.ConteinerProfessor>
                      <AvatarGroupComponent images={professor} size="xsmall" max={4} />
                      <S.TextInfoLabel>
                        {countTeacher
                          ? `${truncateText(professor[0].txName, 15)} +${countTeacher}`
                          : truncateText(professor[0].txName, 15)}{' '}
                        {!isSameUser && <span>- você</span>}
                      </S.TextInfoLabel>
                    </S.ConteinerProfessor>
                  </Tooltip>
                )}
              </>
            )}
          </S.Wrapper>
        </S.TrackDescription>
      </S.HeaderInfo>

      {moment?.every((track) => track.coMomentStatus === 'AGEN') && (
        <S.NotGeneratedWaring>
          <UilExclamationCircle size={isMobile ? 40 : 20} />
          <S.Span>A plataforma ainda não gerou as aulas para essa trilha</S.Span>
        </S.NotGeneratedWaring>
      )}

      {moment && (
        <ScheduleModalComponent
          open={isModalOpen}
          actionCloseModal={actionCloseModalSchedule}
          idTrack={idTrack}
          classId={Number(classId)}
          moments={moment}
          type={validateType(dataClassInfos?.class.items[0].schoolGrade?.grade?.stage?.coStage as string, 'edit')}
          title={txTitle as string}
          coStage={dataClassInfos?.class.items[0].schoolGrade?.grade?.stage?.coStage}
          dataClassInfos={dataClassInfos}
          queryName={['TrailLessonInformationByTrack']}
          routing={true}
          router={`/class-details/${classId}`}
        />
      )}
    </S.ContentHeader>
  )
}
