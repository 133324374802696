import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

import { IGetStudentsGroup, IGetStudentsGroupLesson, IStudentsProjectList } from '~/services/Students/types'

import { IGetStudentHistory } from '~/pages/private/StudentDashboard/interfaces'

export const getStudents = async (name: string, page: number, size: number, orderBy: number, idClassList: number[]) => {
  const idClassQueryString = idClassList.reduce((queryString, idClassItem) => {
    return queryString + `&idClass=${idClassItem}`
  }, '')

  const { data, headers } = await HTTPClient.get(
    idClassList?.length > 0
      ? `professor/students?studentName=${name}&pageNumber=${page}&pageSize=${size}&orderBy=${orderBy}${idClassQueryString}`
      : `professor/students?studentName=${name}&pageNumber=${page}&pageSize=${size}&orderBy=${orderBy}`,
  )

  return { data, headers }
}

export const useStudents = (name: string, page: number, size: number, orderBy: number, idClassList: number[]) =>
  useQuery(['getStudents'], () => getStudents(name, page, size, orderBy, idClassList))

export const getStudentsLesson = async (classID: number) => {
  const { data } = await HTTPClient.get(`/class/${classID}/students`)

  return data
}

export const useGetStudents = (classID: number) =>
  useQuery(['getStudentsLesson'], () => getStudentsLesson(classID), { enabled: !!classID })

//

export const useStudentsGroup = (name: string, page: number, size: number, orderBy: number) =>
  useQuery(['getStudents'], () => getStudents(name, page, size, orderBy))

export const getStudentsLessonGroup = async (classID: number, nuOrder: number) => {
  const { data } = await HTTPClient.get(`/lesson-moment-group/${classID}/groups/${nuOrder}`)

  return data
}

export const useGetStudentsGroup = (classID: number, nuOrder: number) =>
  useQuery<Array<IGetStudentsGroup>>(['getStudentsLessonGroup'], () => getStudentsLessonGroup(classID, nuOrder))

export const getStudentsLessonGroupLesson = async (idLessonMoment: number, nuOrder: number) => {
  const { data } = await HTTPClient.get(`/lesson-moment-group/${idLessonMoment}/groups/${nuOrder}`)

  return data
}

export const useGetStudentsGroupLesson = (idLessonMoment: number, nuOrder: number) =>
  useQuery<IGetStudentsGroupLesson>(['getStudentsGroupLesson'], () =>
    getStudentsLessonGroupLesson(idLessonMoment, nuOrder),
  )

export const useGetStudentsForDocument = (idLessonMoment: number, nuOrder: number, enabled?: boolean) =>
  useQuery<IGetStudentsGroupLesson>(
    ['getStudentsForDocumentation'],
    () => getStudentsLessonGroupLesson(idLessonMoment, nuOrder),
    { enabled: enabled },
  )

export const getStudentsLessonGroupLessonL1 = async (idLessonMoment: number, nuOrder: number) => {
  const { data } = await HTTPClient.get(`/lesson-moment-group/${idLessonMoment}/groups/${nuOrder}`)

  return data
}

export const useGetStudentsGroupLessonL1 = (idLessonMoment: number, nuOrder: number) =>
  useQuery(['getStudentsLessonGroupL1'], () => getStudentsLessonGroupLessonL1(idLessonMoment, nuOrder))

export const getStudentsLessonGroupLessonL3 = async (idLessonMoment: number, nuOrder: number) => {
  const { data } = await HTTPClient.get(`/lesson-moment-group/${idLessonMoment}/groups/${nuOrder}`)

  return data
}

export const useGetStudentsGroupLessonL3 = (idLessonMoment: number, nuOrder: number) =>
  useQuery(['getStudentsLessonGroupL3'], () => getStudentsLessonGroupLessonL3(idLessonMoment, nuOrder))

// PROJECT

export const getStudentsProject = async (classID: number) => {
  const { data } = await HTTPClient.get(`/class/${classID}/students`)

  return data
}

export const useGetStudentsProject = (classID: number) =>
  useQuery(['getStudentsProject'], () => getStudentsProject(classID))

export const useStudentsGroupProject = (name: string, page: number, size: number, orderBy: number) =>
  useQuery(['getStudents'], () => getStudents(name, page, size, orderBy))

export const getStudentsProjectGroup = async (idProjectMoment: number) => {
  const { data } = await HTTPClient.get<IStudentsProjectList>(`/project-moment-group/${idProjectMoment}/groups`)

  return data
}

export const useGetStudentsGroupProject = (
  idProjectMoment: number,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>,
) => useQuery<any[]>(['getIdenticalLessonMoments'], () => getStudentsProjectGroup(idProjectMoment), options)

// History

export const getStudentHistoryMoment = async (idStudent: number, pageNumber: number, coGrade: string) => {
  const { data, headers } = await HTTPClient.get(
    `moment/student/${idStudent}/history?pageNumber=${pageNumber}&pageSize=10&coGrade=${coGrade}`,
  )
  return { data, headers }
}

export const useStudentHistoryMoment = (idStudent: number, pageNumber: number, coGrade: string) =>
  useQuery(['getStudentHistory'], () => getStudentHistoryMoment(idStudent, pageNumber, coGrade), {
    staleTime: Infinity,
  })

const getStudentHistory = async ({
  studentID,
  competenceId,
  pageNumber,
  pageSize,
  coGrade,
}: {
  studentID: number
  competenceId: number
  pageNumber: number
  pageSize: number
  coGrade: string
}) => {
  const response = await HTTPClient.get(
    `/moment/student/${studentID}/history/competence/${competenceId}?pageNumber=${pageNumber}&pageSize=${pageSize}&coGrade=${coGrade}`,
  )

  return {
    data: response.data,
    headers: response.headers,
  }
}

export const useGetStudentHistory = ({
  studentID,
  competenceId,
  pageNumber,
  pageSize,
  coGrade,
}: {
  studentID: number
  competenceId: number
  pageNumber: number
  pageSize: number
  coGrade: string
}) =>
  useQuery<{ data: IGetStudentHistory; headers: any }, Error>(
    ['getStudentHistory', studentID, competenceId, pageNumber, pageSize, coGrade],
    (context: QueryFunctionContext) => {
      const { queryKey } = context
      const [, studentID, competenceId, pageNumber, pageSize, coGrade] = queryKey
      return getStudentHistory({ studentID, competenceId, pageNumber, pageSize, coGrade })
    },
  )
