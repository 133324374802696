import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { Span } from './../../../containers/private/Fund2/Trails/Header/Header.styles'

export const Container = styledMUI(Box)(() => ({
  minHeight: '152px',
  minWidth: '350px',
  maxWidth: '450px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '32px',
  gap: '10px',
}))

export const SmallView = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '448px',
}))

export const ContentIcon = styledMUI(Box)(() => ({
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E8F6FF',
  borderRadius: '50px',
}))

export const BtnClose = styledMUI(Box)(() => ({
  position: 'relative',
  left: '155px',
  top: '-20px',
  color: '#000',
  cursor: 'pointer',
  padding: 0,
}))

export const ContentIconFull = styledMUI(Box)(({ color }) => ({
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: `${color}`,
  borderRadius: '50px',
}))

export const Title = styledMUI(Typography)(() => ({
  textAlign: 'center',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '150%',
  color: '#000',
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  textAlign: 'center',
  color: '#000',
}))

export const ContentButtons = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  flexDirection: 'column',

  '@media (max-width: 700px)': {
    flexDirection: 'column-reverse',
  },
}))

export const ContentInfoBox = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '12px 8px 12px 8px',
  borderRadius: '8px',
  height: '72px',
  width: '302px',
  border: '2px solid #BDC3CF',

  '@media (max-width: 700px)': {
    flexDirection: 'column-reverse',
  },
}))

export const ContentInfoTitle = styledMUI(Span)(() => ({
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  textAlign: 'center',
  paddingBottom: '8px',
}))

export const TextInfoWarn = styledMUI(Span)(() => ({
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  textAlign: 'center',
  padding: '8px 4px',
  borderRadius: '4px',
}))

export const ContentInfoWarn = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '10px',
  borderRadius: '8px',
  border: 'none',
}))
