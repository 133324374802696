import { useMutation } from '@tanstack/react-query'

import { HTTPClient } from '~/server/HTTPClient'

export interface IServiceEngageTrail {
  classId: number
  lessonId?: number
  projectId?: number
  body: Record<string, never>
}

export const EngageTrail = async (prop: IServiceEngageTrail) => {
  const { data } = await HTTPClient.post(
    `/moment/class/${prop.classId}/engage-track/${prop.lessonId || prop.projectId}`,
    prop.body,
  )

  return data
}

export const useServiceEngageTrail = () => useMutation(['DesengageTrail'], EngageTrail)
