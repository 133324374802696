import { Link } from 'react-router-dom'

import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, List, ListItem, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { colorsStepsActived, colorsStepsDisabled } from '~/validations'

export const AccordionContainer = styledMUI(Accordion)(() => ({
  backgroundColor: '#F9FAFC',
  borderRadius: '8px',
  boxShadow: 'none',

  '&.MuiAccordion-root:before': {
    display: 'none',
  },
}))

export const AccordionSummaryContent = styledMUI(AccordionSummary)(() => ({
  padding: '0px 16px',
  margin: '0px',
}))

export const Title = styledMUI(Typography)<{ type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep' }>(
  ({ type }) => ({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '120%',
    color: colorsStepsActived(type),
  }),
)

export const CircleIcon = styledMUI(Box)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E2E5EC',
  borderRadius: '50%',
}))

export const AccordionDetailsContent = styledMUI(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0px 16px 16px',
  margin: '0px',
}))

export const ContentParagraphs = styledMUI(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const Paragraph = styledMUI(ListItem)(() => ({
  display: 'flex',
  alignItems: 'start',
  gap: '16px',
}))

export const ContentPointer = styledMUI(Box)(() => ({
  padding: '6px 0',
}))

export const Pointer = styledMUI(Box)<{ type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep' }>(
  ({ type }) => ({
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    backgroundColor: colorsStepsActived(type),
  }),
)

export const TextParagraph = styledMUI(Box)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const ContentCards = styledMUI(List)(() => ({
  width: '950px',
  display: 'flex',
  alignItems: 'stretch',
  gap: '8px',
  overflowX: 'hidden',
}))

export const CardLink = styledMUI(Link)(() => ({
  textDecoration: 'none',
}))

export const Card = styledMUI(ListItem)<{
  type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep'
  maxHeight: number
}>(({ type, maxHeight }) => ({
  minHeight: '108px',
  height: maxHeight ? `${maxHeight}px` : 'none',
  width: '192px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colorsStepsDisabled(type),
  borderRadius: '8px',
  gap: '8px',
}))

export const Loading = styledMUI(Backdrop)(() => ({}))

export const TextCard = styledMUI(Typography)<{ type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep' }>(
  ({ type }) => ({
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    color: colorsStepsActived(type),
    textAlign: 'center',
  }),
)
