import { Box, Select, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const Table = styled.table`
  border-collapse: collapse;

  td,
  th {
    border: 1px solid ${theme.colors.midMedium};
  }

  td {
    border-bottom: 0;
  }

  tr:first-child th {
    border-top: 0;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  tr td:first-child,
  tr th:first-child {
    border-left: 0;
  }

  tr td:last-child,
  tr th:last-child {
    border-right: 0;
  }

  thead {
    text-align: end;
    hr {
      border: 0px solid ${theme.colors.midDark};
      margin: 0;
    }
    th {
      border: 1px solid ${theme.colors.highPure};
      font-size: 12px;
      font-weight: 700;
      color: ${theme.colors.midDark};
      padding-left: 13px;
      max-width: 74px;
    }
  }
`

export const GanttContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: absolute;
  top: 0;
  gap: 8px;
  @media (max-width: 600px) {
    gap: 2px;
  }
`

export const GanttRow = styled.div`
  display: flex;
`

export const GanttBar = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  margin-right: 2px;
  border-radius: 8px;
  padding: 8px;
  background-color: ${theme.colors.actionLight};
  cursor: pointer;

  :hover {
    background-color: ${theme.colors.actionPure};

    p,
    h4 {
      color: ${theme.colors.highPure};
    }
  }

  h4,
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  h4 {
    color: ${theme.colors.actionPure};
    font-size: 12px;
    font-weight: 700;
  }

  p {
    color: ${theme.colors.actionPure};
    font-size: 12px;
    font-weight: 400;
  }
  @media (max-width: 600px) {
    position: relative;
  }
`

export const TBody = styled.tbody`
  position: relative;
  td {
    width: 70px;
    height: 100vh;
  }
  @media (max-width: 600px) {
    background-color: ${theme.colors.midLight};
    position: absolute;
  }
`
export const TooltipContainer = styledMUI(Box)({
  position: 'relative',
})

export const TooltipContainerHover = styledMUI(Box)({
  '&:hover $customPaper': {
    display: 'block',
  },
})

export const OrderScheduleBox = styled.div`
  background-color: ${theme.colors.highPure};
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ContainerOrderSchedule = styledMUI(Box)(() => ({
  width: '98.89%',
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
}))

export const InfoText = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.lowLight};
`

export const TrailTitle = styled.h3`
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.lowPure};
`

export const ConteinerProfessor = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '4px',
  border: `1px solid ${theme.colors.lowLight}`,
  borderRadius: '4px',
  padding: '4px',
  height: '24px',
}))

export const NameProfessor = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: ${theme.colors.lowPure};
`

export const ContentOrderSchedule = styledMUI(Box)(() => ({
  width: '17.69px',
  height: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '64px',
  backgroundColor: '#BDC3CF',
}))

export const TextOrderSchedule = styledMUI(Typography)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '100%',
  color: '#fff',
}))
export const SelectContent = styledMUI(Select)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  padding: '4px 8px',
  height: '24px',
  minWidth: '130px',
  boxShadow: 'none',
  color: theme.colors.actionPure,
  fontWeight: 700,
  fontSize: '14px',
  '&:hover': {
    cursor: 'normal',
  },
}))

export const ButtonSelected = styled.button<{ active: boolean }>`
  display: flex;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  color: ${({ theme, active }) => (active ? theme.colors.actionPure : theme.colors.highDark)};
  border: 1px solid ${({ theme, active }) => (active ? theme.colors.actionPure : theme.colors.highDark)};
  background-color: ${({ theme }) => theme.colors.highPure};
  cursor: pointer;
`

export const ContentSelectSchedule = styled.div``

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: end;
  align-items: center;
`
export const ContainerButtonsActions = styled.div`
  display: flex;
  gap: 4px;
`
