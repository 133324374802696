import { createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import { gaEvents } from '~/events'

import { useAuth } from '~/_context/Auth'
import { useSnackbar } from '~/_context/Snackbar'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { useServiceGetLesson } from '~/services/Lesson'
import { useServiceManageStudents } from '~/services/ManageStudents'
import { useGetStudentsGroupLesson } from '~/services/Students'
import { useTrailDataUnavaible } from '~/services/Trails'

import { initialDataStudentsGroup } from '~/pages/private/Fund1/default.state'

import { defaultDataState } from './default.states'
import { ILessonPageContextProps, ILessonPageProviderProps } from './interfaces'
import { GET_DETAILS_FOR_LESSON } from './schemas.queries'

const LessonPageContext = createContext<ILessonPageContextProps>({
  isLoading: false,
  data: defaultDataState,
  handleManageStudents: () => Object,
  refetch: async () => Promise.resolve(),
  idClass: 0,
  dataStudentsGroup: initialDataStudentsGroup,
  isLoadingStudentsGroup: false,
  isLoadingManageStudents: false,
  errorDataStudentsGroup: {},
  isFetchingStudentsGroup: false,
  isSameUser: false,
  dataDetails: undefined,
  dataTraisDataUnavaible: undefined,
  isLoadingDetails: false,
  refetchDataDetails: undefined,
  refetchStudentsGroup: undefined,
  isClassInStatus: true,
})

const LessonPageProvider = ({ children }: ILessonPageProviderProps) => {
  const { idLessonMoment } = useParams()
  const queryClient = useQueryClient()

  const { user } = useAuth()
  const navigate = useNavigate()
  const { showSnackbarError } = useSnackbar()

  const { isLoading, data, refetch, remove } = useServiceGetLesson(Number(idLessonMoment))

  const idUserProfessor = data?.idUserProfessor
  const isClassInStatus = data?.class?.inStatus

  const isSameUser = user?.id_user === idUserProfessor

  useEffect(() => {
    queryClient.invalidateQueries(['getLesson']).then()
    remove()
    refetch().then()
    // eslint-disable-next-line
  }, [idLessonMoment])

  const {
    isLoading: isLoadingDetails,
    data: dataDetails,
    refetch: refetchDataDetails,
  } = useQueryGraphQLClient(
    'moment',
    'GET_DETAILS_FOR_LESSON',
    GET_DETAILS_FOR_LESSON,
    {
      idClass: Number(data?.class?.idClass),
      idProjectTrack: Number(data?.idLessonTrack),
    },
    {
      enabled: !!data?.class?.idClass && !!data?.idLessonTrack,
    },
  )

  const idTrack = data?.idLessonTrack
  const idClass = data?.class?.idClass
  const {
    isLoading: isLoadingManageStudents,
    data: dataManageStudents,
    error: errorManageStudents,
    mutate: handleManageStudents,
  } = useServiceManageStudents()

  const { data: dataTraisDataUnavaible } = useTrailDataUnavaible(Number(data?.class?.idClass))

  const {
    data: dataStudentsGroup,
    refetch: refetchStudentsGroup,
    isLoading: isLoadingStudentsGroup,
    error: errorDataStudentsGroup,
    isFetching: isFetchingStudentsGroup,
  } = useGetStudentsGroupLesson(Number(idLessonMoment), Number(2))

  useEffect(() => {
    if (!isLoadingManageStudents && dataManageStudents) {
      refetch().then()
      refetchStudentsGroup().then()
    }
  }, [dataManageStudents, isLoadingManageStudents, refetch, refetchStudentsGroup])

  useEffect(() => {
    if (!isLoadingManageStudents && errorManageStudents) {
      showSnackbarError(
        errorManageStudents?.response?.data?.message?.length
          ? errorManageStudents?.response?.data?.message
          : errorManageStudents?.response?.data?.title,
      )
    }
  }, [errorManageStudents, isLoadingManageStudents, showSnackbarError])

  const handleSchedule = useCallback(() => {
    if (idClass && idTrack) {
      navigate(`/schedule/${idClass}/track/${idTrack}`)
    }
    gaEvents.eventSelectNewTrailButton()
  }, [idClass, idTrack, navigate])

  const lessonPageProviderValues = useMemo(() => {
    return {
      isLoading,
      data: data,
      handleManageStudents,
      refetch,
      isLoadingManageStudents,
      dataStudentsGroup: dataStudentsGroup || initialDataStudentsGroup,
      isLoadingStudentsGroup,
      errorDataStudentsGroup,
      isFetchingStudentsGroup,
      idClass: data?.class?.idClass || 0,
      dataDetails,
      isLoadingDetails,
      refetchDataDetails,
      refetchStudentsGroup,
      dataTraisDataUnavaible,
      isSameUser,
      isClassInStatus,
      handleSchedule,
    }
  }, [
    isLoading,
    data,
    handleManageStudents,
    refetch,
    isLoadingManageStudents,
    dataStudentsGroup,
    isLoadingStudentsGroup,
    errorDataStudentsGroup,
    isFetchingStudentsGroup,
    dataDetails,
    isLoadingDetails,
    refetchDataDetails,
    refetchStudentsGroup,
    dataTraisDataUnavaible,
    isSameUser,
    isClassInStatus,
    handleSchedule,
  ])

  return <LessonPageContext.Provider value={lessonPageProviderValues}>{children}</LessonPageContext.Provider>
}

const useLessonPageContext = () => useContext(LessonPageContext)

export { LessonPageProvider, useLessonPageContext }
