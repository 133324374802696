import styled, { css, DefaultTheme, FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components'

interface IButtonProps {
  variant: 'outline' | 'solid' | 'text' | 'disabled'
  color?: 'success' | 'error' | 'primary' | 'unavaible' | 'disabled'
  size: 'xsmall' | 'small' | 'medium' | 'large' | 'extra-large' | 'large-plus'
  fontWeight?: 'normal' | 'bold'
  fontColor?: 'black'
  fullWidth?: boolean
  fontSize?: 'large' | 'medium' | 'small'
}

const colorDefinitions = (theme: DefaultTheme, color: string | undefined): string => {
  switch (color) {
    case 'success':
      return theme.colors.positivePure
    case 'error':
      return theme.colors.negativePure
    case 'primary':
      return theme.colors.primaryPure
    case 'unavaible':
      return theme.colors.highDark
    case 'disabled':
      return theme.colors.lowLight
    default:
      return theme.colors.actionPure
  }
}

const colorHoverDefinitions = (theme: DefaultTheme, color: string | undefined): string => {
  switch (color) {
    case 'success':
      return theme.colors.positiveDark
    case 'error':
      return theme.colors.negativeDark
    case 'primary':
      return theme.colors.primaryPure
    case 'unavaible':
      return theme.colors.highDark
    case 'disabled':
      return theme.colors.lowLight
    default:
      return theme.colors.actionDark
  }
}

const backgroundColorHoverForTextVariantDefinitions = (theme: DefaultTheme, color: string | undefined): string => {
  if (color === 'disabled' || color === 'unavaible') return 'transparent'

  switch (color) {
    case 'success':
      return theme.colors.positiveLight
    case 'error':
      return theme.colors.negativeLight
    case 'primary':
      return theme.colors.primaryLight
    case 'unavaible':
      return theme.colors.highDark
    case 'disabled':
      return theme.colors.lowLight
    default:
      return theme.colors.actionLight
  }
}
const getCssStyle = (height: string, padding: string, fontSize: string): FlattenSimpleInterpolation => {
  return css`
    height: ${height};
    padding: ${padding};
    font-style: normal;
    font-size: ${fontSize};
  `
}

const buttonSizeSettings = (size: string): readonly SimpleInterpolation[] => {
  switch (size) {
    case 'xsmall':
      return getCssStyle('24px', '10px 12px', '14px')
    case 'small2':
      return getCssStyle('24px', '4px 8px', '14px')
    case 'small-header':
      return getCssStyle('32px', '8px 16px', '16px')
    case 'small':
      return getCssStyle('32px', '4px 8px', '14px')
    case 'medium':
      return getCssStyle('32px', '0 10px', '20px')
    case 'large-plus':
      return getCssStyle('48px', '0 57.18px', '20px')
    case 'extra-large':
      return getCssStyle('48px', ' 0 78.19px', '20px')
    default:
      // large
      return getCssStyle('48px', '8px 16px', '20px')
  }
}

const fontSizeDefinitions = (fontSize: string | undefined): string => {
  switch (fontSize) {
    case 'large':
      return '20px'
    case 'medium':
      return '16px !important'
    default:
      return '14px'
  }
}

export const Button = styled.button<IButtonProps>`
  border-radius: 50px;
  cursor: ${({ color }) => (color !== 'disabled' && color !== 'unavaible' ? 'pointer' : 'default')};
  font-style: normal;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  display: flex;
  font-weight: ${({ fontWeight }) => (fontWeight !== 'bold' ? 400 : 700)};
  font-size: ${({ fontSize }) => fontSizeDefinitions(fontSize)};
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.6s;

  .animate-text {
    opacity: 0;
    transition: opacity 0.6s;
  }

  ${({ variant, color, size, fontColor }) => {
    switch (variant) {
      case 'text':
        return css`
          background-color: inherit;
          border: transparent;
          color: ${({ theme }) => (fontColor !== 'black' ? colorDefinitions(theme, color) : theme.colors.lowPure)};
          ${buttonSizeSettings(size)}

          ${color !== 'unavaible' &&
          color !== 'disabled' &&
          css`
            &:hover {
              color: ${({ theme }) => colorHoverDefinitions(theme, color)};
            }
          `}
        `

      case 'solid':
        return css`
          color: ${({ theme }) => (fontColor !== 'black' ? theme.colors.highPure : theme.colors.lowPure)};
          background-color: ${({ theme }) => colorDefinitions(theme, color)};
          border: 1px solid ${({ theme }) => colorDefinitions(theme, color)};
          cursor: ${color !== 'unavaible' ? 'pointer' : 'default'};
          ${buttonSizeSettings(size)}

          ${color !== 'unavaible' &&
          color !== 'disabled' &&
          css`
            &:hover {
              background-color: ${({ theme }) => colorHoverDefinitions(theme, color)};
              box-shadow: ${({ theme }) => theme.shadows['shadow-level-3']};
            }
          `}
        `

      case 'outline':
        return css`
          color: ${({ theme }) => (fontColor !== 'black' ? colorDefinitions(theme, color) : theme.colors.lowPure)};
          background: transparent;
          border: 1px solid ${({ theme }) => colorDefinitions(theme, color)};
          ${buttonSizeSettings(size)}

          ${color !== 'unavaible' &&
          color !== 'disabled' &&
          css`
            &:hover {
              color: ${({ theme }) => colorHoverDefinitions(theme, color)};
              border-color: ${({ theme }) => colorHoverDefinitions(theme, color)};
              box-shadow: ${({ theme }) => theme.shadows['shadow-level-3']};
            }
          `}
        `

      case 'disabled':
        return css`
          color: ${({ theme }) => (fontColor !== 'black' ? theme.colors.lowLight : theme.colors.lowPure)};
          background-color: ${({ theme }) => theme.colors.highDark};
          border: 1px solid ${({ theme }) => theme.colors.highDark};
          cursor: default;
          ${buttonSizeSettings(size)}
        `

      case 'animated':
        return css`
          color: ${({ theme }) => (fontColor !== 'black' ? colorDefinitions(theme, color) : theme.colors.lowPure)};
          background: transparent;
          border: 1px solid ${({ theme }) => colorDefinitions(theme, color)};
          padding: 0;
          height: 32px;
          width: auto;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          border-radius: 50px;
          ${buttonSizeSettings(size)}

          .animate-text {
            opacity: 0;
            width: 0px;
            display: none;
            padding: 0;
            overflow: hidden;
            white-space: nowrap;
            transition: opacity 0.6s, width 0.6s, display 0.6s;
          }

          ${color !== 'unavaible' &&
          color !== 'disabled' &&
          css`
            &:hover {
              color: ${({ theme }) => colorHoverDefinitions(theme, color)};
              border-color: ${({ theme }) => colorHoverDefinitions(theme, color)};
              box-shadow: ${({ theme }) => theme.shadows['shadow-level-3']};
              padding: 0 12px;

              .animate-text {
                opacity: 1;
                display: inline;
                width: auto;
                padding-left: 8px;
              }
            }
          `}
        `
    }
  }}
`
