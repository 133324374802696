export default {
  colors: {
    feedback: {
      success: {
        main: '#0CD04D',
        light: '#E1FFEB',
        medium: '#8CFFB2',
        dark: '#007025',
      },
      error: {
        main: '#D44333',
        light: '#FFF7F5',
        medium: '#F27B6C',
        dark: '#5A0A00',
      },
    },
    primaryLight: '#F7E5FF',
    primaryMedium: '#E4A7FF',
    primaryPure: '#8C22BC',
    primaryDark: '#410B3C',

    secondaryLight: '#FFE7E3',
    secondaryMedium: '#FFA498',
    secondaryPure: '#E66251',
    secondaryDark: '#7C0E00',

    tertiaryLight: '#FFF7DD',
    tertiaryMedium: '#FFE69A',
    tertiaryPure: '#F7C327',
    tertiaryDark: '#A67C00',

    quaternaryLight: '#FFEEF5',
    quaternaryMedium: '#FF90BB',
    quaternaryPure: '#E14983',
    quaternaryDark: '#97003A',

    lowLight: '#A3A3A3',
    lowMedium: '#666666',
    lowDark: '#292929',
    lowPure: '#000000',

    highPure: '#FFFFFF',
    highLight: '#FAFAFA',
    highMedium: '#E0E0E0',
    highDark: '#CCCCCC',

    midLight: '#F8FAFF',
    midMedium: '#E2E5EC',
    midPure: '#BDC3CF',
    midDark: '#7C8189',

    actionLight: '#E8F6FF',
    actionLow: '#F4F7FE',
    actionMedium: '#90D1FF',
    actionPure: '#0095FF',
    actionDark: '#005B9C',

    positiveLight: '#E1FFEB',
    positiveMedium: '#8CFFB2',
    positivePure: '#29CC5F',
    positiveDark: '#007025',

    warningLight: '#FFF1E1',
    warningMedium: '#FFC989',
    warningPure: '#FF8A00',
    warningDark: '#6A3900',

    negativeLight: '#FFF7F5',
    negativeMedium: '#F27B6C',
    negativePure: '#D44333',
    negativeDark: '#5A0A00',
  },
  typography: {
    fontWeights: {
      black: 900,
      extrabold: 800,
      bold: 700,
      semibold: 600,
      medium: 500,
      regular: 400,
      light: 300,
      extralight: 200,
      thin: 100,
    },
    fontSizes: {
      giant: '56px',
      display: '48px',
      xxxl: '40px',
      xxl: '32px',
      xl: '24px',
      lg: '20px',
      md: '18px',
      sm: '16px',
      xs: '14px',
      xxs: '12px',
      xxxs: '10px',
    },
    lineHeights: {
      default: '100%',
      sm: '120%',
      lg: '130%',
      xl: '140%',
      xxl: '170%',
    },
    headings: {
      headingGiant: {
        fontWeight: 'bold',
        fontSize: '56px',
        lineHeight: '100%',
      },
      headingDisplay: {
        fontWeight: 'bold',
        fontSize: '48px',
        lineHeight: '100%',
      },
      headingXXXLarge: {
        fontWeight: 'bold',
        fontSize: '40px',
        lineHeight: '100%',
      },
      headingXXLarge: {
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '100%',
      },
      headingXLarge: {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '100%',
      },
      headingLarge: {
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '100%',
      },
      headingMedium: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '100%',
      },
      headingSmall: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '100%',
      },
    },
    caption: {
      large: {
        fontWeight: 'regular',
        fontSize: '20px',
        lineHeight: '120%',
      },
      medium: {
        fontWeight: 'regular',
        fontSize: '18px',
        lineHeight: '120%',
      },
      small: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '120%',
      },
      xSmall: {
        fontWeight: 'regular',
        fontSize: '14px',
        lineHeight: '120%',
      },
    },
  },
  shadows: {
    'shadow-level-1': '0 1px 2px rgba(0,0,0,0.32)',
    'shadow-level-2': '0 2px 4px rgba(0,0,0,0.4)',
    'shadow-level-3': '0 4px 8px rgba(0,0,0,0.08)',
    'shadow-level-4': '0 8px 24px rgba(0,0,0,0.16)',
    'shadow-level-5': '0 16px 32px rgba(0,0,0,0.16)',
    'shadow-level-6': '0 16px 48px rgba(0,0,0,0.24)',
  },
}
